import styles from "./ErrorHandler.module.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useNavigate } from "react-router-dom";
const { Link } = require("react-router-dom");
import { useTranslation } from "react-i18next";

export default function ErrorHandler({ title, desc }) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	function goToHomePage() {
		navigate("/");
        window.location.reload();
	}

	function goBack() {
		navigate(-1);
        window.location.reload();
	}

	return (
		<div className={styles["error_container"]}>
			<div className={styles["text_container"]}>
				<WarningAmberIcon sx={{ fontSize: 90, color: "#ff0f0f" }} />
				<h1 className={styles["title_style"]}>
					{t("Oops!")} {title}
				</h1>
				<div className={styles["text_style"]}>
					<div>{desc}</div>
				</div>
				<div className='btn-group' style={{'justifyContent':'center'}}>
					<button className={`btn btn-primary`} onClick={goToHomePage}>
						{t("Go to homepage")}
					</button>
					<button className={`btn btn-secondary`} onClick={goBack}>
						{t("Go Back")}
					</button>
				</div>
			</div>
		</div>
	);
}
