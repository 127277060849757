import React, { memo, useContext } from "react";
import "./Footer.css";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import useUser from "../../hooks/useUser";
import { useTranslation } from "react-i18next";
import { SOCIAL_MEDIA_LINKS, USERTYPES } from "../../utils/constants";
import termsAndConditionDoc from "../../assets/docs/Dealer Source Terms and Conditions 04-15-20.pdf";

const Footer = memo(() => {
	const { t } = useTranslation();
	const { isLoggedIn, activeCustomerGroup, activeRoles,isInternal } = useUser();
	// const isZorPro = activeCustomerGroup.charAt(0) === "Z" || activeRoles.includes("PRO");
	const isConsumer = activeCustomerGroup.charAt(0) === "Y" || activeRoles.includes("CONSUMER");
	const socialMediaLinks = isConsumer ? SOCIAL_MEDIA_LINKS.CONSUMER : SOCIAL_MEDIA_LINKS.PROFESSIONAL;

	return (
		<>
			<hr className='hr-separator' />
			<footer className='footer'>
				<div className='container footer--container'>
					<div className='footer-main'>
						<nav className='footer-column' aria-label={t('Products And Solutions')}>
							<h2 id='prodcutsAndSolutions'>{t("Products And Solutions")}</h2>
							<ul aria-labelledby='prodcutsAndSolutions'>
								<li>
									<a href='https://electronics.sony.com/' target='_blank' rel='noopener noreferrer'>
										{t("Consumer Products")}
										<span className='sr-only'>(opens in new tab)</span>
									</a>
								</li>
								<li>
									<a href='https://pro.sony.com' target='_blank' rel='noopener noreferrer'>
										{t("Professional Products")}
										<span className='sr-only'>(opens in new tab)</span>
									</a>
								</li>
							</ul>
						</nav>
						<nav className='footer-column' aria-label={t('Terms')}>
							<h2 id='terms'>{t("Terms")}</h2>
							<ul aria-labelledby='terms'>
								<li>
									<a href='https://www.sony.com/electronics/terms-conditions' target='_blank' rel='noopener noreferrer'>
										{t("SEL Terms and Conditions")} <span className='sr-only'>(opens in new tab)</span>
									</a>
								</li>
								<li>
									<a href={termsAndConditionDoc} target='_blank' rel='noopener noreferrer'>
										{t("Dealer Source Terms & Conditions")}
									</a>
								</li>
							</ul>
						</nav>
						<nav className='footer-column' aria-label='Privacy policies'>
							<h2 id='privacy-section'>{t("Privacy")}</h2>
							<ul aria-labelledby='privacy-section'>
								<li>
									<a href='https://electronics.sony.com/privacy-policy' target='_blank' rel='noopener noreferrer'>
										{t("SEL Privacy Policy")} <span className='sr-only'>(opens in new tab)</span>
									</a>
								</li>
								<li>
									<a href='https://electronics.sony.com/privacy-policy#DataPractices' target='_blank' rel='noopener noreferrer'>
										{t("CA Privacy Notice")} <span className='sr-only'>(opens in new tab)</span>
									</a>
								</li>
								<li>
									<a href='http://corporate.sony.ca/view/privacy.htm' target='_blank' rel='noopener noreferrer'>
										{t("Canada Privacy Policy")} <span className='sr-only'>(opens in new tab)</span>
									</a>
								</li>
								<li>
									<a href='https://products.sel.sony.com/SEL/legal/DNSRequest.html' target='_blank' rel='noopener noreferrer'>
										{t("Do Not Sell My Personal Information")}
										<span className='sr-only'>(opens in new tab)</span>
									</a>
								</li>
							</ul>
						</nav>

						<nav className='footer-column'>
							{isLoggedIn ? (
								<>
									<h2 id='contact-us'>{t("Contact Us")}</h2>
									<ul aria-labelledby='contact-us'>
										<li style={{ lineHeight: "22px" }}>
											{t("For login issue, email GlobalServiceDesk.US@am.sony.com or call")} <span style={{ whiteSpace: "nowrap" }}>877-652-7669 (USA)</span>
											. {t("Include the website, your name, email address, company name and the issue")}
										</li>
									</ul>
								</>
							) : (
								<>
									<h2 id='dealer-signUp'>Dealer SignUp</h2>
									<ul aria-labelledby='dealer-signUp'>
										<li>
											<a href='https://na.info.pro.sony/Request_to_become_a_sony_dealer.html' target='_blank' rel='noopener noreferrer'>
												Apply<span className='sr-only'>(opens in new tab)</span>
											</a>
										</li>
									</ul>
								</>
							)}
						</nav>
					</div>
					<div className='footer-middle'>
						{isLoggedIn && !isInternal && <SocialMedia socialMediaLinks={socialMediaLinks} />}
						{isLoggedIn && isInternal && (
							<>
								<SocialMedia userType={"Consumer"} socialMediaLinks={SOCIAL_MEDIA_LINKS.CONSUMER} />
								<SocialMedia userType={"Professional"} socialMediaLinks={SOCIAL_MEDIA_LINKS.PROFESSIONAL} />
							</>
						)}
					</div>
					<div className='footer-bottom'>
						<p className='copyright'>
							© {new Date().getUTCFullYear()} {t("SONY ELECTRONICS INC. (SEL)")}
						</p>
					</div>
				</div>
			</footer>
		</>
	);
});
function SocialMedia({ userType, socialMediaLinks }) {
	return (
		<div>
			{userType && <p>{userType}</p>}
			<nav id='followUS' className='social-media' aria-label='Social Media Links'>
				<a className='fb' target='_blank' rel='noreferrer' href={socialMediaLinks.Facebook}>
					<FacebookOutlinedIcon aria-hidden='true' focusable='false' sx={{ verticalAlign: "bottom" }} />
					<span className='sr-only'>Follow us on Facebook</span>
				</a>
				<a className='twitter' target='_blank' rel='noreferrer' href={socialMediaLinks.Twitter}>
					<XIcon aria-hidden='true' focusable='false' sx={{ verticalAlign: "bottom" }} />
					<span className='sr-only'>Follow us on X (formerly Twitter)</span>
				</a>
				<a className='insta' target='_blank' rel='noreferrer' href={socialMediaLinks.Instagram}>
					<InstagramIcon aria-hidden='true' focusable='false' sx={{ verticalAlign: "bottom" }} />{" "}
					<span className='sr-only'>Follow us on Instagram</span>
				</a>
				<a className='youtube' target='_blank' rel='noreferrer' href={socialMediaLinks.YouTube}>
					<YouTubeIcon aria-hidden='true' focusable='false' sx={{ verticalAlign: "bottom" }} />
					<span className='sr-only'>Subscribe to our YouTube channel</span>
				</a>
			</nav>
		</div>
	);
}
export default Footer;
