import React from 'react';
import './ConfirmationModal.css';
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({ message, onConfirm, onCancel,isLoading }) => {
  const { t } = useTranslation();
  return (
    <div className="confirmation-modal">
      <div className="modal-overlay">
        <div className="modal-content">
          <h2>{message}</h2>
          <div className="modal-actions">
            <button className="confirm-btn" onClick={onConfirm} disabled={isLoading}>{t("Confirm")}</button>
            <button className="cancel-btn" onClick={onCancel}disabled={isLoading}>{t("Cancel")}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
