import i18n from "i18next";
import CanadaFlag from "../assets/img/flags/canada-512.png";
import USAFlag from "../assets/img/flags/unitedStates-512.png";
import { downloadBulletinPDF } from "../service/SalesNews";
const ExcelJS = require("exceljs");
import { saveAs } from "file-saver";
import { signOut } from "aws-amplify/auth";
import { LOGOUT_USER } from "../store/reducer/userReducer";
import { logoutCookies } from "../service/common";
import { USERTYPES } from "./constants";
export const removeHash = (event) => {
	event.preventDefault();
};

export const languageOptions = {
	"en-US": {
		lang: "US",
		country: "us",
		countryCode: "us",
		languageCode: "en-us",
		label: "US English",
		flag: USAFlag,
	},
	// "es_US": {//removed as per business decision
	//   lang: "ES",
	//   country: "us",
	//   countryCode: "us",
	//   languageCode: "es-US",
	//   label: "US Spanish",
	//   flag: USAFlag,
	// },
	"en-CA": {
		lang: "CAN",
		country: "ca",
		countryCode: "ca",
		languageCode: "en-ca",
		label: "CAN English",
		flag: CanadaFlag,
	},
	"fr-CA": {
		lang: "FR",
		country: "ca",
		countryCode: "ca",
		languageCode: "fr-ca",
		label: "CAN French",
		flag: CanadaFlag,
	},
};

export async function downloadZippedPDFs(bulletin_nos) {
	const pdfUrls = await downloadBulletinPDF(bulletin_nos);
	console.log("Download pdfs", pdfUrls);
	const link = document.createElement("a");
	link.href = pdfUrls;
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export const generateMonthYearOptions = () => {
	const options = [];
	const now = new Date();
	for (let i = 0; i < 12; i++) {
		const monthYear = new Date(now.getFullYear(), now.getMonth() - i, 1);
		const month = monthYear.toLocaleString("default", { month: "short" });
		const year = monthYear.getFullYear();
		options.push(`${month}-${year}`);
	}
	return options;
};

export function convertLngToUnderScore(lng) {
	return lng.replace("-", "_");
}
export const titleCase = (str) => str[0].toUpperCase() + str.slice(1)
export function camilCaseToSentence(str) {
	return str
		.replace(/([a-z])([A-Z])/g, "$1 $2")
		.toLowerCase()
		.replace(/(^|\s)\S/g, (match) => match.toUpperCase());
}
export async function createExcel(cols, data) {
	const workbook = new ExcelJS.Workbook();
	workbook.creator = "Dealer Source";
	workbook.lastModifiedBy = "Dealer Source";
	const sheet = workbook.addWorksheet("Sheet 1");
	let finalCols = [];
	cols.forEach((col) => {
		if (col.headerName != "Checkbox selection") {
			finalCols.push({ header: col.headerName, key: col.field });
			if (col?.valueFormatter) {
				data = data.map((row) => {
					let toFormat = row[col.field];
					toFormat = col.valueFormatter(toFormat);
					return { ...row, [col.field]: toFormat };
				});
			}
		}
	});
	sheet.columns = finalCols;
	sheet.addRows(data);
	const buffer = await workbook.xlsx.writeBuffer();
	const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
	saveAs(blob, "my-excel-file.xlsx");
}

export function hasAccess(permissions, userRoles, userCustomerGroup, activeUserType) {
	let arrayRoles
	if(!Array.isArray(userRoles)){
		arrayRoles=[userRoles]
	}else{
		arrayRoles=userRoles
	}
	const isIndirect = (arrayRoles.some(role => role.toUpperCase().includes("INDIRECT"))) && (activeUserType.toUpperCase() === "B2B");
	const hasRolePermission = permissions.roles.length === 0 || permissions.roles.some(role => arrayRoles.includes(role));
	const hasGroupPermission = permissions.customerGroups.length === 0 || permissions.customerGroups.some(custGr => userCustomerGroup === custGr);
	const hasLangPermission = !permissions.lang || permissions.lang === i18n.language;
	if (activeUserType.toUpperCase() === USERTYPES.INTERNAL && permissions.internal === "*") {
		return true && hasLangPermission;
	}
	if (isIndirect) { return hasRolePermission && hasLangPermission; }
	return hasRolePermission && hasGroupPermission && hasLangPermission;
}
export async function handleLogout(isInternal,setUserState, navigate, queryClient, cb) {
	try {
		if (isInternal) await logoutCookies();
	} catch (error) {
		console.log("Failed to Clear cookies")
	}
	signOut().then(() => {
		setUserState({
			type: LOGOUT_USER,
		});
		if (cb) cb()
		queryClient?.clear()
		navigate("/");
	});
};
