import style from "./email-popup.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { CheckCircle, ErrorOutline, Send } from "@mui/icons-material";
import { Autocomplete, Chip, CircularProgress, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { emailAsset } from "../../service/productsService";
import useEscapeKey from "../../hooks/useEscapeKey";
import useUser from "../../hooks/useUser";
import { useTranslation } from "react-i18next";
export default function EmailPopup({ pimcoreId, isProduct, active, setClose, customStyles }) {
	//todo EMAIL
	const { t } = useTranslation();
	const { activeUserEmail } = useUser();
	const [emails, setEmails] = useState([]);
	const [sending, setSending] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const queryclient = useQueryClient();

	useEscapeKey(() => {
		setClose(false);
	});
	const { isError, isSuccess, isFetching } = useQuery({
		//TODO rate limiting
		queryKey: ["sendEmail"],
		queryFn: () => {
			return emailAsset(
				pimcoreId,
				isProduct,
				emails.map(({ value }) => value)
			);
		},
		enabled: sending,
		retry: false,
		staleTime: 0,
	});
	useEffect(() => {
		if (emails.length === 0) {
			activeUserEmail ? setEmails([{ label: activeUserEmail, value: activeUserEmail }]) : setEmails([{ label: null, value: null }]);
		}
		queryclient.resetQueries({ queryKey: ["sendEmail"], exact: true });
	}, [active]);

	useEffect(() => {
		let timer;
		if (isSuccess) {
			setShowSuccess(true);
			timer = setTimeout(() => {
				queryclient.resetQueries({ queryKey: ["sendEmail"], exact: true });
				setClose(false);
				setShowSuccess(false);
			}, 2000);
		}
		setSending(false);
		return () => {
			if (timer) clearTimeout(timer);
		};
	}, [isError, isSuccess]);

	return (
		<div
			className={active ? "backdrop backdrop--show" : "backdrop"}
			style={{ zIndex: "var(--zIndexEmailPopUp)", ...(customStyles || {}) }}
			onClick={() => {
				setClose(false);
			}}>
			<div
				className={style.emailPopUp}
				onClick={(event) => {
					event.stopPropagation();
				}}>
				<h3>{t("Email Details")}</h3>
				<p className={style.subHeading}>{t("To email the asset, please enter the recipient's email address(es) below.")}</p>
				<Autocomplete
					multiple
					freeSolo
					selectOnFocus
					clearOnBlur
					handleHomeEndKeys
					options={emails.map(({ label }) => label)}
					value={emails}
					onChange={(event, newValue) => {
						const newState = newValue.map((value) => {
							if (typeof value === "string") {
								return {
									label: value,
									value,
								};
							} else if (value && value.inputValue) {
								return {
									label: value.inputValue,
									value: value.inputValue,
								};
							} else {
								return value;
							}
						});

						setEmails(newState);
					}}
					filterOptions={(options, params) => {
						const { inputValue } = params;
						const filtered = [];
						const isExisting = options.some((label) => label.startsWith(inputValue));
						if (inputValue !== "" && !isExisting) {
							filtered.push({ label: `Add "${inputValue}"`, inputValue });
						}
						return filtered;
					}}
					renderTags={(value, getTagProps) =>
						value.map((option, index) => {
							const { key, ...tagProps } = getTagProps({ index });
							return <Chip variant='filled' label={option?.label} key={key} {...tagProps} />;
						})
					}
					sx={{ flexGrow: "1" }}
					renderInput={(params) => <TextField {...params} variant='standard' label='To :' placeholder={t('Email')} />}
				/>

				<div className={style.sendButton}>
					<button
						className={showSuccess ? "btn btn-success" : "btn btn-primary"}
						onClick={() => {
							if (emails.length !== 0) setSending(true);
							else {
								//TODO can't be empty
							}
						}}
						disabled={isError || isFetching || showSuccess}>
						{isFetching ? (
							<div className='btn-icon'>
								<CircularProgress size='20px' sx={{ alignSelf: "center" }} color='secondary' />
								{t("Sending")}
							</div>
						) : showSuccess ? (
							<div className='btn-icon'>
								<CheckCircle />
								<b>{t("Sent")}</b>
							</div>
						) : (
							<div className='btn-icon'>
								<Send />
								{t("Send")}
							</div>
						)}
					</button>
				</div>
				{isError && (
					<div className={style.error}>
						<div className='btn-icon'>
							<ErrorOutline />
						</div>
						{t("Something went wrong. Try again Later")}
					</div>
				)}
				<div
					className='close-button'
					onClick={() => {
						setClose(null);
					}}>
					<CloseIcon fontSize='large' />
				</div>
			</div>
		</div>
	);
}
