import React, { useEffect, useState } from "react";
import CompanyInformationAccordion from "./CompanyInformationAccordion";
import CompanyContactsAccordion from "./CompanyContactsAccordion";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./CompanyContactsPage.css";
import { PageLoader } from "../../component/loader/PLoader";
import { getCompanyDetails } from "../../service/adminService";
import { useQuery } from "@tanstack/react-query";
import useUser from "../../hooks/useUser";
import { useTranslation } from "react-i18next";

const CompanyContactsPage = () => {
	const { t } = useTranslation();
	const { activeUserEmail } = useUser();
	const [companyInfo, setCompanyInfo] = useState(null);
	const [contacts, setContacts] = useState([]);
	const [expandedSections, setExpandedSections] = useState({
    companyInfo: false,
    companyContracts: true,
    roleDescription: true
  });
  const toggleSection = (sectionName) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionName]: !prev[sectionName]
    }));
  };
	const { isFetching, data, error } = useQuery({
		queryKey: ["companyDetails", activeUserEmail],
		queryFn: getCompanyDetails,
	});

	useEffect(() => {
		if (data) {
			setCompanyInfo(data?.companyInfo);
			setContacts(data?.usersList);
		}
	}, [data]);

	if (isFetching) return <PageLoader />;
	if (error) return <div>{t("Error")}: {error}</div>;

	return (
		<div className='container'>
			<div className='accordion-container'>
				<CompanyInformationAccordion
					companyInfo={companyInfo}
					expanded={expandedSections.companyInfo}
					onChange={() => toggleSection('companyInfo')}
				/>
				<CompanyContactsAccordion
					contacts={contacts}
					setContacts={setContacts}
					expanded={expandedSections.companyContracts}
					onChange={() => toggleSection('companyContracts')}
				/>
				<Accordion expanded={expandedSections.roleDescription} onChange={() => toggleSection('roleDescription')}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography sx={{ fontWeight: "550" }}>{t("Role Description")}</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<img src='\DS_Role_Matrix.png' alt='Role Mapping' className='role-mapping-image' />
					</AccordionDetails>
				</Accordion>
			</div>
		</div>
	);
};

export default CompanyContactsPage;
