import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { DataGrid, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, useGridApiContext } from "@mui/x-data-grid";
import { LinearProgress } from "@mui/material";

// import { createFakeServer } from "@mui/x-data-grid-generator";
import { MenuItem } from "@mui/material";
import SearchResultPagination from "./SearchResultPagination";
import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import { createExcel } from "../../utils/Utils";
import Download from "../download/Download";
import { useTranslation } from "react-i18next";

const SearchResultContext = createContext();
export const SEARCH_RESULT_KEY='searchResult'
export function useSearchResultContext() {
	const cntx = useContext(SearchResultContext);
	if (!cntx) {
		throw new Error("Should be Child of Search Result");
	}
	return cntx;
}

const LinearProgressSx = {
	mt: 3,
	backgroundColor: "#e5e5e5",
	"& .MuiLinearProgress-bar": {
		backgroundColor: "#0d90c0",
	},
	mb: 3,
};

function DownloadAsZipMenuItem({ hideMenu }) {
	const { t } = useTranslation();
	const apiRef = useGridApiContext();
	const { downloadFns, addDownloadPromise } = useSearchResultContext();
	const { title, zip } = downloadFns;

	return (
		<MenuItem
			onClick={() => {
				const selectedRows = Array.from(apiRef.current.getSelectedRows().values());
				// const cols = apiRef.current.getVisibleColumns();
				if (selectedRows.length !== 0) {
					const rowsIds = selectedRows.map((v) => v.id);
					addDownloadPromise({
						title,
						fn: zip(rowsIds),
					});
				} else {
					const currentRowsIds = Array.from(apiRef.current.getAllRowIds().values());
					if (currentRowsIds.length !== 0)
						addDownloadPromise({
							title,
							fn: zip(currentRowsIds),
						});
				}

				hideMenu?.();
			}}>
			{t("Download Files")}
		</MenuItem>
	);
}
function ExcelExportMenuItem({ hideMenu}) {
	const apiRef = useGridApiContext();
	const { t } = useTranslation();

	return (
		<MenuItem
			onClick={() => {
				const selectedRows = Array.from(apiRef.current.getSelectedRows().values());
				const cols = apiRef.current.getVisibleColumns();
				if (selectedRows.length !== 0) {
					console.log(selectedRows); // This will log the selected rows
					createExcel(cols, selectedRows);
				} else {
					const currentRowsId = Array.from(apiRef.current.getAllRowIds().values());
					const data = currentRowsId.map((id) => ({
						...apiRef.current.getRow(id),
					}));
					createExcel(cols, data);
				}

				hideMenu?.();
			}}>
			{t("Export Excel")}
		</MenuItem>
	);
}
function CustomExportButton({ csvOptions, printOptions, ...other }) {
	return (
		<GridToolbarExportContainer {...other}>
			<DownloadAsZipMenuItem />
			<ExcelExportMenuItem />
		</GridToolbarExportContainer>
	);
}
function CustomToolbar(props) {
	const {...others} = props;
	return (
		<GridToolbarContainer style={{ justifyContent: "end", paddingBlock: "10px", paddingInline: "20px" }} {...others}>
			<CustomExportButton />
			<GridToolbarDensitySelector />
		</GridToolbarContainer>
	);
}
function SearchResult({ columns, searchFn, searchData, downloadFns ,title}) {
	const { t } = useTranslation();
	const enabled = Object.keys(searchData).length !== 0;
	const [downloadPromises, setDownloadPromises] = useState([]);
	const addDownloadPromise = useCallback(
		(dwnPromise) => {
			setDownloadPromises((prevState) => {
				return [...prevState, dwnPromise];
			});
		},
		[setDownloadPromises]
	);
	const clearDownloads = useCallback(() => {
		setDownloadPromises([]);
	}, [setDownloadPromises]);
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState(() => {
		let col = columns.find((v) => v?.sort) ?? columns[0];
		return [
			{
				field: col.field,
				sort: col.sort ?? "asc",
			},
		];
	});
	const { isFetching: searchFetching, data: searchResultData } = useQuery({
		queryFn: () => searchFn(paginationModel, sortModel, searchData),
		queryKey: [title,SEARCH_RESULT_KEY,paginationModel, sortModel, searchData],
		placeholderData: (previousData) => previousData,
		enabled,
		//todo check this
		gcTime: ms("1h"),
		staleTime: ms("1h"),
	});
	const rowCountRef = useRef(searchResultData?.totalRowCount || 0);

	const rowCount = React.useMemo(() => {
		if (searchResultData?.totalRowCount !== undefined) {
			rowCountRef.current = searchResultData.totalRowCount;
		}
		return rowCountRef.current;
	}, [searchResultData?.totalRowCount]);
	
	const CustomToolbarWrapper = (props) =>
	{
		return <CustomToolbar {...props} t={t} />;
	}

	return (
		<>
			{searchFetching ? <LinearProgress sx={LinearProgressSx} /> : <hr style={{ marginTop: "26px" }} className='horizontal-ruler' />}
			<SearchResultContext.Provider value={{ downloadFns, addDownloadPromise, downloadPromises, clearDownloads }}>
				<div className='search__results'>
					<div className='search__heading'>{t("Results")}</div>
					<DataGrid
						autoHeight
						disableRowSelectionOnClick
						keepNonExistentRowsSelected
						rows={searchResultData?.rows}
						columns={columns}
						checkboxSelection
						paginationMode='server'
						loading={searchFetching}
						pageSizeOptions={[5, 10, 25]}
						onPaginationModelChange={setPaginationModel}
						paginationModel={paginationModel}
						rowCount={rowCount}
						disableColumnMenu
						sortingMode='server'
						onSortModelChange={useCallback((newSortModel) => setSortModel(newSortModel), [])}
						sortModel={sortModel}
						slots={{ toolbar: CustomToolbarWrapper, pagination: SearchResultPagination }}
					/>
				</div>
				{downloadPromises.length !== 0 && <Download />}
			</SearchResultContext.Provider>
		</>
	);
}

export default SearchResult;
