import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Checkbox, Snackbar, Alert } from '@mui/material';
import { fetchCurrentSchedules, fetchScheduleTemplates, amendSchedules } from '../../../service/scheduleAmendmentsService';
import { useParams } from 'react-router-dom';
import './ScheduleAmendmentsPage.css';
import { useTranslation } from "react-i18next";

const ScheduleAmendmentsPage = () => {
  const { t } = useTranslation();
  const { contractNumber } = useParams();
  const [isLoading, setIsLoading] = useState(true); 
  const [currentSchedules, setCurrentSchedules] = useState([]);
  const [scheduleTemplates, setScheduleTemplates] = useState([]);
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const schedules = await fetchCurrentSchedules(contractNumber, t);
        setCurrentSchedules(schedules);

        const templates = await fetchScheduleTemplates(contractNumber,t);
        setScheduleTemplates(templates.rows);
      } catch (error) {
        console.error("Error fetching schedules:", error);
      } finally {
        setIsLoading(false); 
      }
    };

    fetchData();
  }, [contractNumber]);

  const handleScheduleSelect = (scheduleId) => {
    setSelectedSchedules((prev) =>
      prev.includes(scheduleId)
        ? prev.filter((id) => id !== scheduleId) // Uncheck
        : [...prev, scheduleId] // Check
    );
  };

  const handleAmendSchedules = async () => {
    if (selectedSchedules.length === 0) return;

    setIsLoading(true); 
    try {
      const response = await amendSchedules(contractNumber, selectedSchedules);

      if (response) {
        setSuccessMessage("Schedules amended successfully!");
        setOpenSnackbar(true);
        setSelectedSchedules([]);

        // Refetch current schedules
        const updatedSchedules = await fetchCurrentSchedules(contractNumber, t);
        setCurrentSchedules(updatedSchedules);
      }
    } catch (error) {
      console.error("Error amending schedules:", error);
      setSuccessMessage("Failed to amend schedules. Please try again.");
      setOpenSnackbar(true);
      setSelectedSchedules([]);
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div className="schedule-amendments-page">
      <h1 className="page-heading">{t("Schedule Amendments for Contract")} {contractNumber}</h1>
      <hr className="horizontal-ruler" />

      {/* Current Schedules Section */}
      <div className="current-schedules">
        <h2>{t("Current Schedules Amended")}</h2>
        <DataGrid
          rows={currentSchedules}
          columns={[
            { field: 'schedule_number', headerName: t('Schedule Number'), width: 150 },
            { field: 'schedule_pdf_name', headerName: t('Schedule Name'), width: 300 },
          ]}
          pageSize={5}
          autoHeight
        />
      </div>

      {/* Choose Schedules Section */}
      <div className="choose-schedules">
        <h2>{t("Choose a Schedule to Amend:")}</h2>
        <DataGrid
          rows={scheduleTemplates}
          columns={[
            { field: 'id', headerName: t('Schedule Number'), width: 200 },
            { field: 'schedulename', headerName: t('Schedule Name'), width: 300 },
            { field: 'pro_rate_amount', headerName: t('Prorate Amount'), width: 200 },
            {
              field: 'select',
              headerName: t('Select'),
              width: 200,
              renderCell: (params) => (
                <Checkbox
                  checked={selectedSchedules.includes(params.row.id)}
                  onChange={() => handleScheduleSelect(params.row.id)}
                />
              ),
            },
          ]}
          pageSize={5}
          autoHeight
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleAmendSchedules}
          disabled={isLoading || selectedSchedules.length === 0} 
          style={{ marginTop: '10px' }}
        >
          {isLoading ? "LOADING..." : t("AMEND SCHEDULE")}
        </Button>

        <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={() => setOpenSnackbar(false)}>
          <Alert severity={successMessage.includes("Failed") ? "error" : "success"}>
            {successMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default ScheduleAmendmentsPage;
