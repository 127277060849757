import { useQueryClient } from "@tanstack/react-query";
import { IMPERSONATED_JWT, USER_STATE } from "../../utils/constants";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const IMPERSONATE_USER = "IMPERSONATE_USER";
export const DE_IMPERSONATE_USER = "DE_IMPERSONATE_USER";

const NULL_USER_INFO = Object.freeze({
	displayName: null,
	family_name: null,
	given_name: null,
	email: null,
});

const NULL_STATE = Object.freeze({
	loggedIn: false,
	cognitoUser: NULL_USER_INFO,
	cognitoRoles: [],
	impersonated: false,
	impersonatedUser: NULL_USER_INFO,
	impersonatedRoles: [],
	impersonatedCustomerGrp: '',
	impersonatedAccountObj: null,
	impersonateContactObj: null,
	status: "",
	sapUserRole: [],
	customerGroup: "",
	userType: "",
});
export function loggedInStateInit() {
	const userState = JSON.parse(sessionStorage.getItem(USER_STATE));
	if (!userState) {
		return NULL_STATE;
	}
	return {
		loggedIn: userState?.loggedIn ?? false,
		cognitoUser: userState?.cognitoUser ?? NULL_USER_INFO,
		cognitoRoles: userState?.cognitoRoles ?? [],
		impersonatedUser: userState?.impersonatedUser ?? NULL_USER_INFO,
		impersonatedRoles: userState?.impersonatedRoles ?? [],
		status: userState?.status,
		impersonated: userState?.impersonated ?? false,
		impersonatedCustomerGrp:userState?.impersonatedCustomerGrp??'',
		impersonatedAccountObj: userState?.impersonatedAccountObj ?? null,
		impersonateContactObj: userState?.impersonateContactObj ?? null,
		sapUserRole: userState?.sapUserRole ?? [],
		customerGroup: userState?.customerGroup ?? "",
		userType: userState?.userType ?? "",
	};
}
export function userReducer(state, { type, payload }) {
	let finalState = {};
	switch (type) {
		case LOGIN_USER:
			const { loggedIn, userInfo, accessrole, sapUserRole, customerGroup, status, userType } = payload;
			const userState = {
				loggedIn,
				cognitoUser: userInfo,
				cognitoRoles: accessrole,
				status,
				userType,
				sapUserRole,
				customerGroup,
			};
			finalState = userState;
			break;
		case LOGOUT_USER:
			sessionStorage.clear();
			localStorage.clear();
			finalState = NULL_STATE;
			break;
		case IMPERSONATE_USER:
			const { jwt, roles, sapUserInfo, selectedCompanyObj, selectedUserObj, customer_group} = payload;
			if (!jwt) {
				finalState=state
				break;
			}
			sessionStorage.setItem(IMPERSONATED_JWT, jwt);
			const impersonatedState = {
				impersonatedUser: { given_name: sapUserInfo.given_name, displayName: null, family_name: sapUserInfo.family_name, email: sapUserInfo.email },
				impersonatedRoles: roles,
				impersonated: true,
				impersonatedCustomerGrp:customer_group,
				impersonatedAccountObj: selectedCompanyObj, //It is not just the accountID
				impersonateContactObj: selectedUserObj,
			};
			finalState = { ...state, ...impersonatedState };
			break;
		case DE_IMPERSONATE_USER:
			sessionStorage.removeItem(IMPERSONATED_JWT);
			const deImpersonatedState = {
				impersonatedUser: NULL_USER_INFO,
				impersonatedRoles: [],
				impersonatedCustomerGrp:'',
				impersonated: false,
				impersonatedAccountObj: null,
				impersonateContactObj: null,
			};
			finalState = { ...state, ...deImpersonatedState };
			break;
		default:
			break;
	}
	sessionStorage.setItem(USER_STATE, JSON.stringify(finalState));
	return finalState;
}
