import { CircularProgress } from "@mui/material";
import { AppContext } from "../../store/AppProvider";
import styles from "./logoutTimer.module.css";
import { useNavigate } from "react-router-dom";
import useEscapeKey from "../../hooks/useEscapeKey";
import { handleLogout } from "../../utils/Utils";
import { useQueryClient } from "@tanstack/react-query";
import useUser from "../../hooks/useUser";
import { useTranslation } from "react-i18next";
const { useEffect, useState, useContext } = require("react");

export default function LogOutTimer({ timeoutDuration = 5000, setOpen }) {
	const {isInternal}=useUser()
	const { t } = useTranslation();
	const [countdown, setCountdown] = useState(Math.floor(timeoutDuration / 1000));
	const { setUserState } = useContext(AppContext);
	const MAX = timeoutDuration / 1000;
	const MIN = 0;
	const normalise = (value) => ((value - MIN) * 100) / (MAX - MIN);
	const navigate = useNavigate();
	useEscapeKey(() => setOpen(false));
	const queryClient = useQueryClient();
	useEffect(() => {
		// Start countdown
		const timer = setInterval(() => {
			setCountdown((prevCount) => {
				if (prevCount <= 1) {
					clearInterval(timer);
					callLogout();
					return 0;
				}
				return prevCount - 1;
			});
		}, 1000);
		return () => clearInterval(timer);
	}, []);
	//TODO remove
	console.log("Showing Logout PopUp");
	const callLogout = async () => {
		await handleLogout(isInternal,setUserState, navigate, queryClient, () => {
			setOpen(false);
		});
	};

	return (
		<div className={styles.logoutContainer}>
			<h2>{t("Logging out due to inactivity in")}</h2>
			<div className={styles.progress}>
				<CircularProgress variant='determinate' value={normalise(countdown)} />
				<div className={styles.progressLabel}>{countdown}</div>
			</div>
            <div className="btn-group">
			<button className='btn btn-secondary' onClick={() => setOpen(false)}>{t("Cancel")}</button>
			<button className='btn btn-primary' onClick={() => handleLogout()}>{t("Logout")}</button>
            </div>
		</div>
	);
}
