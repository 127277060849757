import React, { memo, useContext, useEffect, useState } from "react";
import "./Header.css";
import SonyLogo from "../../assets/sony-logo.svg";
import { signInWithRedirect, signOut } from "@aws-amplify/auth";
import SearchIcon from "@mui/icons-material/Search";
import { AppContext } from "../../store/AppProvider";
import { useTranslation } from "react-i18next";
import { handleLogout, hasAccess, languageOptions } from "../../utils/Utils";
import ArrowBottomSVG from "../../assets/img/Arrow-bottom.svg";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link, useNavigate } from "react-router-dom";
import useKeyboardDialog from "../../hooks/useKeyboardDialog";
import useUser from "../../hooks/useUser";
import { accountSearchPermissions } from "../../store/staticMenuItems";
import { useQueryClient } from "@tanstack/react-query";

// const CloseIconMemo = memo(() => <CloseIcon sx={{ fontSize: 30, color: "white" }} />);
// const MenuIcon = memo(() => <AppsIcon sx={{ fontSize: 30, color: "white" }} />);

const Header = ({ setGlobalSearchState, sideNav: isOpen, setSideNav: setSideNavState }) => {
	const { isLoggedIn } = useUser();
	const navigate = useNavigate();
	const handleLoginClick = () => signInWithRedirect();
	const toggleMenu = (currState) => {
		setSideNavState(currState);
	};

	return (
		<header className='main-header' id='ds-main-header'>
			<div className='container main-header--container'>
				<div className='header__left'>
					{/* {isLoggedIn && (
					<div
						className='header__left--menuButton'
						onClick={() => {
							toggleMenu(!isOpen);
						}}
						role='button'
						aria-label='Navigation Menu'>
						{isOpen ? <CloseIcon sx={{ fontSize: 30, color: "white" }} /> : <AppsIcon sx={{ fontSize: 30, color: "white" }} />}
					</div>
				)} */}
					<Link to={"/"} style={{ display: "flex" }}>
						<img
							src={SonyLogo}
							alt='Dealer Source'
							className='logo'
						// role="link"
						// onClick={() => {
						// 	navigate("/");
						// }}
						/>
					</Link>
					{/* {isLoggedIn &&
          <>
            <span className="separator">|</span>
            <span className="dealer-source">
              <span className="header-dealer">DEALER</span>
              <span className="header-source">SOURCE</span>
            </span>
          </>
        } */}
				</div>
				{isLoggedIn ? (
					<LoggedInRightSection setGlobalSearchState={setGlobalSearchState} />
				) : (
					<button className='btn btn-primary login-button-header' onClick={handleLoginClick}>
						LOGIN
					</button>
					// <div className="dummyDiv"></div>
				)}
			</div>
		</header>
	);
};

const LoggedInRightSection = memo(function LoggedInRightSection({ setGlobalSearchState }) {
	const { setUserState } = useContext(AppContext);
	const [lngRef, lngIsOpen, toggleLng] = useKeyboardDialog();
	const { isAuthorized } = useUser();
	const [profileRef, profileIsOpen, toggleProfile] = useKeyboardDialog();
	const { activeUserType, isInternal,activeCustomerGroup, cognitoRoles } = useUser();
	const {
		i18n,t
	} = useTranslation();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [
		langDropOption,
		// setLangDropOption
	] = useState(languageOptions);
	const [language, setLanguage] = useState(languageOptions[i18n.resolvedLanguage].lang);
	const [flag, setFlag] = useState(languageOptions[i18n.resolvedLanguage].flag);

	const handleChangeLanguage = (key, e) => {
		console.log(key);
		i18n.changeLanguage(key);
		setLanguage(langDropOption[key].lang);
		setFlag(langDropOption[key].flag);
	};
	const callLogout = async () => {
		await handleLogout(isInternal, setUserState, navigate, queryClient, null);
	};

	return (
		<div className='header__right' data-testid='header__right'>
			{isAuthorized && (
				<div className='header__search'>
					<button
						aria-label='Search Products And Assets'
						className='button__shawdow-style'
						aria-haspopup='dialog'
						onClick={() => {
							setGlobalSearchState(true);
						}}>
						<SearchIcon className='header__search--icon' aria-hidden='true' />
						<span id='site-search-label'>{t("Search Products, Assets ...")}</span>
					</button>
				</div>
			)}
			<div className='language__selector'>
				<button
					ref={lngRef}
					className='button__shawdow-style language__selector--current'
					// onClick={toggleLng}
					onKeyDown={(e) => {
						if (e.key === "Enter") toggleLng();
					}}
					data-testid='language-selector'
					aria-label={`Current language: ${languageOptions[i18n.resolvedLanguage].label}. Click to change language`}
					aria-controls='language-dropdown'
					aria-haspopup='true'>
					<img className='language__selector--flag' src={flag} alt='' aria-hidden='true' />
					<span>{language}</span>
					<img src={ArrowBottomSVG} alt='' role='none' className='arrow-bottom' aria-hidden='true' />
					<ul id='language-dropdown' data-testid='language-dropdown' role='menu' className={`header__dropdown ${lngIsOpen ? "show" : ""}`}>
						{Object.keys(langDropOption)?.map((key) => {
							const { label, flag } = langDropOption[key];
							return (
								<li
									tabIndex={lngIsOpen ? 0 : -1}
									role='menuitem'
									className='language__selector--lang'
									key={key}
									onClick={(e) => {
										handleChangeLanguage(key, e);
										toggleLng(false);
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											handleChangeLanguage(key, e);
											toggleLng(false);
										}
									}}>
									<img className='language__selector--flag' src={flag} alt={`flag ${label}`} />
									<span>{label}</span>
								</li>
							);
						})}
					</ul>
				</button>
			</div>
			<div className='header__profile'>
				<button
					ref={profileRef}
					onKeyDown={(e) => {
						if (e.key === "Enter") toggleProfile();
					}}
					// onClick={toggleProfile}
					aria-label='profile dropdown'
					data-testid='profile'
					aria-controls='profile-dropdown'
					aria-haspopup='true'
					className='header__profile--button button__shawdow-style'
				// color='inherit'
				>
					<AccountCircleOutlinedIcon />
					<ul id='profile-dropdown' data-testid='profile-dropdown' className={`header__dropdown ${profileIsOpen ? "show" : ""}`}>
						{hasAccess(accountSearchPermissions, cognitoRoles, activeCustomerGroup, activeUserType) && (
							<li
								role='menuitem'
								tabIndex={profileIsOpen ? 0 : -1}
								onClick={() => {
									navigate("/account-search");
								}}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										navigate("/account-search");
										toggleProfile(false);
									}
								}}>
								{t("Account Search")}
							</li>
						)}
						<li
							role='menuitem'
							tabIndex={profileIsOpen ? 0 : -1}
							onClick={callLogout}
							onKeyDown={(e) => {
								if (e.key === "Enter") callLogout();
							}}>
							{t("Logout")}
						</li>
					</ul>
				</button>
			</div>
		</div>
	);
});

export default Header;
