import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import './AddContractTemplate.css';
import { uploadTemplate } from '../../../../service/contractTemplate';
import { CONTRACT_TEMPLATE_TYPE } from '../../../../utils/constants';
import { useTranslation } from "react-i18next";

const AddContractTemplate = ({ onClose, onSuccess }) => {
  const { t } = useTranslation();
  const { register, handleSubmit,reset } = useForm();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true); 
    setError(null); 
    try {
      await uploadTemplate(data,CONTRACT_TEMPLATE_TYPE,t);
      onSuccess();
      onClose();
    } catch (err) {
      console.error(err)
      setError(t('Failed to upload the contract template. Please try again later.'));
      reset();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>{t("Add New Contract Template")}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-group">
            <label htmlFor="contractType">{t("Contract Type")}</label>
            <select id="contractType" {...register('contractType')} required>
              <option value="">{t("Select a type")}</option>
              <option value="ZGLR">ZGLR</option>
              <option value="ZSI">ZSI</option>
            </select>
          </div>
          <div className="input-group">
            <label htmlFor="title">{t("Title")}</label>
            <input
              id="title"
              type="text"
              {...register('title')}
              placeholder={t("filename with extension")}
              required
            />
          </div>
          
          <div className="input-group">
            <label htmlFor="file">{t("File")}</label>
            <input
              id="file"
              type="file"
              {...register('file')}
              required
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <div className="modal-actions">
          <button type="submit" className="submit-btn" disabled={isLoading}>
          {isLoading ? t('Submitting...') : t('Submit')}
        </button>
        <button type="button" className="close-btn" onClick={onClose} disabled={isLoading}>
          {t("Close")}
        </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddContractTemplate;
