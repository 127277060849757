import { Link, Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "./component/header/Header";
import AppProvider, { AppContext } from "./store/AppProvider";
import { useContext, useEffect } from "react";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import Layout from "./component/layout/Layout";
import Footer from "./component/footer/Footer";
import Callback, { FullPageLoading } from "./component/callback/Callback";
import { ErrorBoundary } from "react-error-boundary";
import GlobalSearch from "./component/globalSearch/GlobalSearch";
import AccountSearch from "./pages/accountSearch/AccountSearch";
import { accountSearchPermissions, catalogPermissions, expiryAssetsPermissions, staticMenuItems } from "./store/staticMenuItems";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { setCatalogSubmenus, setLogosSubmenus } from "./store/reducer/dynamicMenuItemReducer";
import { setStaticSubmenus } from "./store/reducer/staticMenuItemReducer";
import { getCatalog } from "./service/productsService";
import { WarningAmber } from "@mui/icons-material";
import ProductCatalog from "./pages/productCatalog/ProductCatalog";
import LogoCatalog from "./pages/logoCatalog/LogoCatalog";
import ProductPage from "./pages/product/ProductPage";
import ms from "ms";
import useIdle from "./hooks/useIdle";
import LogOutTimer from "./component/logoutTimer/LogoutTimer";
import ScheduleAmendmentsPage from "./pages/contracts/scheduleAmendments/ScheduleAmendmentsPage";
import { useDocumentTitle } from "./hooks/useDocumentTitle";
import ExpiryAssets from "./pages/expiryAssets/ExpiryAssets";
import { REDIRECT_PATH } from "./utils/constants";
import { getCurrentUser, signInWithRedirect } from "aws-amplify/auth";
import useUser from "./hooks/useUser";
import { handleLogout, hasAccess } from "./utils/Utils";
import { useTranslation } from "react-i18next";
import ViewBulletin from "./pages/salesNews/ViewBulletin";
import { PageLoader } from "./component/loader/PLoader";
import ErrorHandler from "./component/errors/ErrorHandler";
import UnAuthorized from "./component/unAuthorized/UnAuthorized";

// import BulletinDownload from "./pages/salesnews/BulletinDownload";
function App() {
	return (
		<ErrorBoundary fallback={<ErrorFallback />}>
			<AppProvider>
				<PrimaryApp />
			</AppProvider>
		</ErrorBoundary>
	);
}
function AppLayout() {
	const [globalSearch, setGlobalSearch] = useState(false);
	const [sideNav, setSideNav] = useState(false);
	return (
		<>
			<GlobalSearch active={globalSearch} setIsActive={setGlobalSearch} />
			<Header setGlobalSearchState={setGlobalSearch} sideNav={sideNav} setSideNav={setSideNav} />
			<Layout sideNav={sideNav} setSideNav={setSideNav} gbSearch={globalSearch} />
			<Footer />
		</>
	);
}

function filterMenuItems(menuItems, userRole, userCustomerGroup, activeUserType, cognitoRoles) {
	try {
		const filterMenu = menuItems.reduce((filteredMenus, menuItem) => {
			// Checking if top level has permission
			if (menuItem.permissions && !hasAccess(menuItem.permissions, userRole, userCustomerGroup, activeUserType)) {
				return filteredMenus;
			}

			if (menuItem?.subMenus) {
				//Processing subMenus
				const subMenus = Object.keys(menuItem.subMenus).reduce((acc, subMenuObjsKey) => {
					//Forming and filtering subMenus Object
					let subMenuObj = menuItem.subMenus[subMenuObjsKey];

					//Checking TOP level permission on subMenu level
					if (subMenuObj.permissions && !hasAccess(subMenuObj.permissions, userRole, userCustomerGroup, activeUserType)) {
						return acc; // top level doesnt have permission
					} else if (subMenuObj.permissions && subMenuObj.items) {
						const itemObj = Object.keys(subMenuObj.items).reduce((filteredItems, itemObjKey) => {
							let item = subMenuObj.items[itemObjKey];
							//Checking on the last level for permission and lng
							if (hasAccess(item.permissions, userRole, userCustomerGroup, activeUserType)) {
								filteredItems[itemObjKey] = item;
							}
							return filteredItems;
						}, {});
						return { ...acc, [subMenuObjsKey]: { ...subMenuObj, items: itemObj } };
					} else {
						return { ...acc, [subMenuObjsKey]: { ...subMenuObj } };
					}
				}, {});
				return [...filteredMenus, { ...menuItem, subMenus: subMenus }];
			}
			return [...filteredMenus, menuItem];
		}, []);
		return filterMenu;
	} catch (e) {
		console.log("error in filterMenuItems", e);
		return [];
	}
}

function SecurityCheck({ permissions, custom }) {
	const { t } = useTranslation();
	const { activeRoles, activeUserType, activeCustomerGroup, cognitoRoles } = useUser();
	if (hasAccess(permissions, activeRoles, activeCustomerGroup, activeUserType)) {
		return <Outlet />;
	} else if (custom && hasAccess(permissions, cognitoRoles, activeCustomerGroup, activeUserType)) {
		return <Outlet />;
	} else {
		return <div>{t("You do not have access to this page.")}</div>;
	}
}
function SecuredRoute({ loggedIn }) {
	const loc = useLocation();

	if (loggedIn) return <Outlet />;
	else {
		localStorage.setItem(REDIRECT_PATH, loc.pathname);
		signInWithRedirect();
	}
}
function PublicRoute({ loggedIn }) {
	const loc = useLocation();
	if (!loggedIn || loc.pathname === "/callback") return <Outlet />;
	else {
		return <Navigate to={"/"} replace />;
	}
}
function RouteWithTitle({ title, element }) {
	useDocumentTitle(title);
	return element;
}
function staticRoutes(staticMenuItems, t) {
	return Object.keys(staticMenuItems).map((menuItemName) => {
		const menuItem = staticMenuItems[menuItemName];
		if (!menuItem?.subMenus) {
			return (
				<Route key={menuItemName} element={<SecurityCheck permissions={menuItem?.permissions} />}>
					<Route path={`/${menuItem.route}`} element={<RouteWithTitle title={menuItem.documentTitle} element={menuItem.element} />} />
				</Route>
			);
		} else {
			return Object.keys(menuItem?.subMenus).map((subMenuName) => {
				const subMenuItem = menuItem?.subMenus[subMenuName];
				if (subMenuItem?.route) {
					return (
						<Route key={subMenuName} element={<SecurityCheck permissions={subMenuItem?.permissions} />}>
							<Route
								path={`/${menuItem.route}/${subMenuItem.route}`}
								element={<RouteWithTitle title={subMenuItem.documentTitle} element={subMenuItem.element} />}
							/>
						</Route>
					);
				}
				return <></>;
			});
		}
	});
}
function PrimaryApp() {
	const { setDynamicMenuItems, setStaticFilteredMenuItems, setUserState } = useContext(AppContext);
	const { t, i18n } = useTranslation();
	// const navigate = useNavigate();
	// const loc = useLocation();
	const lang = i18n.resolvedLanguage;
	const { activeUser, isLoggedIn, isAuthorized, activeRoles, activeUserType, activeCustomerGroup, cognitoRoles } = useUser();
	// const [loadingUser, setLoadingUser] = useState(!isLoggedIn);

	// useEffect(() => {
	// 	const handleTabSession = async () => {
	// 		let userSession;
	// 		try {
	// 			userSession = await getCurrentUser();
	// 			console.log("userSession ",userSession)
	// 			if (userSession && !isLoggedIn) {
	// 				localStorage.setItem(REDIRECT_PATH, loc.pathname);
	// 				navigate("/callback");
	// 			}
	// 		} catch (error) {
	// 			console.log("User not logged in ", userSession);
	// 			if(isLoggedIn){
	// 				handleLogout(null,setUserState,navigate,null,()=>{})
	// 			}
	// 		} finally {
	// 			setLoadingUser(false);
	// 		}
	// 	};
	// 	handleTabSession();
	// }, []);

	const { isFetching: isFetchingDynamic, data: dynamicItems } = useQuery({
		queryKey: ["dynamicItems", activeUser, lang],
		queryFn: async () => getCatalog(lang),
		enabled: isLoggedIn && isAuthorized,
	});

	const { isFetching: isFetchingStatic, data: staticFilteredItems } = useQuery({
		queryKey: ["staticFilteredItems", lang, activeUser],
		queryFn: () => {
			if (!isLoggedIn) return staticMenuItems;
			else if (!isAuthorized) return null;
			else {
				console.log(
					"staticMenuItems, activeRoles, activeCustomerGroup, activeUserType, cognitoRoles:::",
					staticMenuItems,
					activeRoles,
					activeCustomerGroup,
					activeUserType,
					cognitoRoles
				);
				let filteredMenus = filterMenuItems(staticMenuItems, activeRoles, activeCustomerGroup, activeUserType, cognitoRoles);
				console.log("filteredMenus::::::::", filteredMenus);
				return filteredMenus;
			}
		},
	});
	//TODO handle error of static and dynamic items
	useEffect(() => {
		if (!isFetchingDynamic && dynamicItems) {
			setDynamicMenuItems(setCatalogSubmenus(dynamicItems.subMenu));
			setDynamicMenuItems(setLogosSubmenus(dynamicItems.logos));
		}
	}, [dynamicItems]);
	useEffect(() => {
		if (!isFetchingStatic && staticFilteredItems) {
			setStaticFilteredMenuItems(setStaticSubmenus(staticFilteredItems));
		}
	}, [staticFilteredItems]);

	const isIdle = useIdle(isLoggedIn, ms("40m"));
	const [showPopUp, setShowPopUp] = useState(isIdle);
	useEffect(() => {
		if (isIdle) {
			setShowPopUp(true);
		}
	}, [isIdle]);
	if (isFetchingDynamic || isFetchingStatic) {
		return (
			<>
				<PageLoader show />
				<AppLayout />
			</>
		);
	}
	if (isLoggedIn && !isAuthorized) {
		return (
			<Routes>
				<Route element={<PublicRoute loggedIn={isLoggedIn} />}>
					<Route path='/callback' element={<Callback />} />
				</Route>
				<Route element={<AppLayout />}>
					<Route path='*' element={<UnAuthorized />} />
				</Route>
			</Routes>
		);
	}
	return (
		<>
			<div
				className={showPopUp ? "backdrop backdrop--show" : "backdrop"}
				onClick={() => {
					setShowPopUp(false);
				}}
				style={{ zIndex: "var(--zIndexLogoutPopUp)" }}>
				{showPopUp && <LogOutTimer timeoutDuration={ms("1m")} setOpen={setShowPopUp} />}
			</div>
			<Routes>
				<Route element={<PublicRoute loggedIn={isLoggedIn} />}>
					<Route path='/callback' element={<Callback />} />
				</Route>
				<Route element={<AppLayout />}>
					<Route path='/salesNews/viewBulletin' element={<ViewBulletin />} />
					<Route path='/' element={isLoggedIn ? <Home /> : <Login />} />
					<Route element={<SecuredRoute loggedIn={isLoggedIn} />}>
						<Route element={<SecurityCheck permissions={accountSearchPermissions} custom />}>
							<Route path='/account-search' element={<AccountSearch />} />
						</Route>
						<Route path='/schedule-amendments/:contractNumber' element={<ScheduleAmendmentsPage />} />
						<Route element={<SecurityCheck permissions={catalogPermissions} />}>
							<Route path='/catalog/*' element={<ProductCatalog />} />
							<Route path='/product/*' element={<ProductPage />} />
							<Route path='/logos/*' element={<LogoCatalog />} />
						</Route>
						<Route element={<SecurityCheck permissions={expiryAssetsPermissions} t={t} />}>
							<Route path='/expiryAssets' element={<ExpiryAssets />} />
						</Route>
						{staticFilteredItems && staticRoutes(staticFilteredItems, t)}
					</Route>
					<Route path='*' element={<Page404 t={t} />} />
				</Route>
			</Routes>
		</>
	);
}

export function Page404({ t }) {
	useDocumentTitle("Page Not Found");
	return (
		<div className='container' style={{ flexDirection: "column", marginTop: "200px" }}>
			<WarningAmber sx={{ fontSize: 150 }} />
			<h1>{t("404 Not Found")}</h1>
			<p style={{ maxWidth: "50%" }}>
				We apologize for the inconvenience. The page you requested cannot be found. The URL may be incorrect, or the product may have expired or been
				deleted.
			</p>
			<Link to='/'>{t("Return to Home")}</Link>
		</div>
	);
}
function ErrorFallback() {
	useDocumentTitle("Something Went Wrong");
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	return (
		<>
			<div></div>
			<div className='error-fallback'>
				<ErrorHandler title={"Something's wrong here"} desc={"We are working on the fix"} />
			</div>
		</>
	);
}

export default App;
