
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { PdfLink } from '../../../component/searchPage/PdfLink';
import { useTranslation } from "react-i18next";

const GeneratedContractsModal = ({ open, onClose, contractData }) => {
  const { t } = useTranslation();
   function renderLink(params) {
      const {pdf_doc_name,link} = params.value ?? '';
      return (
        <PdfLink href={`${link}`} tabIndex={params.tabIndex}>
        {pdf_doc_name}
        </PdfLink>
      );
      };
  const columns = (t) => [
      { field: 'id', headerName: t('Template ID'), flex: 0.5},
      { field: 'pdf_doc_name', headerName: t('Contract'), flex: 1 ,renderCell:renderLink,valueFormatter: (value) =>  value.pdf_doc_name},
      { field: 'contract_number', headerName: t('Contract Number'), flex: 0.5 },
    ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{t("Generated Contracts")}</DialogTitle>
      <DialogContent>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={contractData?.rows}
            columns={columns(t)}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GeneratedContractsModal;