import styles from "./unauthorized.module.css";
function UnAuthorized(){
	return (
		<div  className={`container ${styles.unAuthContainer}`}>
            <h1>Access Denied !</h1>
			<h2>This is a Restricted Space</h2>
			<p>Unfortunately, you don't have the permissions required to access this page.</p>
			<p>Please contact your administrator for assistance.</p>
		</div>
	);
}

export default UnAuthorized