import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, IconButton, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import { roleMappings } from '../../utils/constants';
import { useTranslation } from "react-i18next";

const AddUserModal = ({ open, onClose, onSubmit, initialValues }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [errors, setErrors] = useState({});

  // Prefill values if in edit mode
  useEffect(() => {
    if (initialValues) {
      console.log('initialValues::::::::', initialValues)
      setFirstName(initialValues.first_name);
      setLastName(initialValues.last_name);
      setEmail(initialValues.email);
      setRole(roleMappings[initialValues?.sap_function_type_code]); // Ensure role is prefilled correctly
    } else {
      setFirstName('');
      setLastName('');
      setEmail('');
      setRole('');
    }
    setErrors({});
  }, [initialValues, open]);

  const validate = () => {
    const newErrors = {};
    if (!firstName.trim()) newErrors.firstName = t('First Name is required');
    if (!lastName.trim()) newErrors.lastName = t('Last Name is required');
    if (!email.trim()) {
      newErrors.email = t('Email is required');
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      newErrors.email = t('Invalid email');
    }
    if (!role.trim()) newErrors.role = t('Role is required');

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validate()) {
      const newUser = { firstName, lastName, email, role };
      onSubmit(newUser);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className="modal-container">
        <Box className="modal-box">
          <IconButton className="close-icon" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" component="h2" gutterBottom>
            {initialValues ? t('Edit User') : t('Add New User')}
          </Typography>
          <TextField
            label={t("First Name")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            margin="normal"
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            label={t("Last Name")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            margin="normal"
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
          <TextField
            label={t("Email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
            error={!!errors.email}
            helperText={errors.email}
          />
          <FormControl fullWidth margin="normal" error={!!errors.role}>
            <InputLabel>{t("Role")}</InputLabel>
            <Select
              value={role}
              onChange={(e) => setRole(e.target.value)}
              label={t("Role")}
            >
              <MenuItem value="Principal/Owner">{t("Principal/Owner")}</MenuItem>
              <MenuItem value="Admin">{t("Admin")}</MenuItem>
              {/* TODO we onlky have transaltion for one menu item? */}
              <MenuItem value="Sales News">{t("Sales News")}</MenuItem>
              <MenuItem value="Dealer Source Only">{t("Dealer Source Only")}</MenuItem>
              <MenuItem value="Purchasing/Buyer">{t("Purchasing/Buyer")}</MenuItem>
              <MenuItem value="Accounting">{t("Accounting")}</MenuItem>
              <MenuItem value="ExpressNet View Only">{t("ExpressNet View Only")}</MenuItem>
            </Select>
            <Typography variant="caption" color="error">
              {errors.role}
            </Typography>
          </FormControl>
          <div className="modal-actions">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              className="send-button"
            >
              {initialValues ? t('Save Changes') : t('Send')}
              <SendIcon sx={{ padding: '10px', fontSize: 'medium' }} />
            </Button>
          </div>
        </Box>
      </div>
    </Modal>
  );
};

export default AddUserModal;
