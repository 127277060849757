import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "react-i18next";


const CompanyInformationAccordion = ({ companyInfo, expanded, onChange }) => {
  const { t } = useTranslation();
  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontWeight: '550' }}>{t("Company Information")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <form className="company-info-form">
          <TextField label={t("Account Number")} value={companyInfo?.soldto_number} InputProps={{ readOnly: true }} fullWidth margin="normal" />
          <TextField label={t("Company Name")} value={companyInfo?.soldto_name} InputProps={{ readOnly: true }} fullWidth margin="normal" />
          <TextField label={t("Street")} value={companyInfo?.shipto_street_line1} InputProps={{ readOnly: true }} fullWidth margin="normal" />
          <TextField label={t("City")} value={companyInfo?.shipto_city} InputProps={{ readOnly: true }} fullWidth margin="normal" />
          <TextField label={t("State")} value={companyInfo?.shipto_region} InputProps={{ readOnly: true }} fullWidth margin="normal" />
          <TextField label={t("Zip")} value={companyInfo?.shipto_postal_code} InputProps={{ readOnly: true }} fullWidth margin="normal" />
          <TextField label={t("Country")} value={companyInfo?.country} InputProps={{ readOnly: true }} fullWidth margin="normal" />
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompanyInformationAccordion;
