import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddUserModal from './AddUserModal';
import { roleMappings } from '../../utils/constants';
import { createContact, deleteContact, updateContact } from '../../service/adminService';
import { useTranslation } from "react-i18next";

const CompanyContactsAccordion = ({ contacts, setContacts, expanded, onChange }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [contactToDelete, setContactToDelete] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const handleOpenModal = () => {
    setEditMode(false);
    setSelectedContact(null);
    setOpenModal(true);
  };

  const handleOpenEditModal = (contact, index) => {
    setEditMode(true);
    setSelectedContact({ ...contact, index });
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  const handleOpenDeleteDialog = (contact) => {
    setContactToDelete(contact);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setContactToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = async () => {
    // const updatedContacts = contacts.filter((_, i) => i !== contactToDelete);
    console.log("Contact to delete::::::::", contactToDelete);
    // setContacts(updatedContacts);
    await deleteContact("0001024668", contactToDelete);
    handleCloseDeleteDialog();
  };

  const addUser = async (newUser) => {
    await createContact("0001024668", newUser);
    handleCloseModal();
  };

  const editUser = async (updatedUser) => {
    console.log("User to update::::::::", updatedUser)
    await updateContact("0001024668", updatedUser)
    handleCloseModal();
  };

  return (
    <>
      <Accordion expanded={expanded} onChange={onChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography sx={{ fontWeight: '550' }}>{t("Company Contacts")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}>
            <Button variant="contained" color="primary" onClick={handleOpenModal}>
              {t("Add User")}
            </Button>
          </div>
          <TableContainer component={Paper} style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('First Name')}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('Last Name')}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t('Email')}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t("Role")}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>{t("Action")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts.map((contact, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ textAlign: 'center' }}>{contact?.first_name}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{contact?.last_name}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{contact?.email}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>{roleMappings[contact?.sap_function_type_code]}</TableCell>
                    <TableCell sx={{ textAlign: 'center' }}>
                      <IconButton onClick={() => handleOpenDeleteDialog(contact)} aria-label={t("Delete Account")}>
                        <DeleteIcon />
                      </IconButton>
                      <IconButton onClick={() => handleOpenEditModal(contact, index)} aria-label={t("Edit Account")}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <AddUserModal
        open={openModal}
        onClose={handleCloseModal}
        onSubmit={editMode ? editUser : addUser}
        initialValues={editMode ? selectedContact : undefined}
      />

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>{t("Confirm Deletion")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t("Are you sure you want to delete this contact?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            {t("Cancel")}
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            {t("Delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CompanyContactsAccordion;
