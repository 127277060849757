import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchForm from "../../../component/searchPage/SearchForm";
import SearchDropdown from "../../../component/searchPage/SearchDropdown";
import SearchInput from "../../../component/searchPage/SearchInput";
import { deleteContract, getContractCriteria, searchContracts, updateLockStatus } from "../../../service/contract";
import { PdfLink } from "../../../component/searchPage/PdfLink";
import { Delete, Edit } from "@mui/icons-material";
import { Button, Checkbox, Snackbar, Alert, CircularProgress } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SEARCH_RESULT_KEY } from '../../../component/searchPage/SearchResult';
import { useTranslation } from "react-i18next";

const TITLE = 'Search Contracts';

function renderLink(params) {
  const { pdf_doc_name, link } = params.value ?? '';
  return (
    <PdfLink href={`${link}`} tabIndex={params.tabIndex}>
      {pdf_doc_name}
    </PdfLink>
  );
}

const columns = (handleAmendClick, handleDeleteClick, handleLockChange, t) => [
  { field: 'contract_number', headerName: t('Contract Number'), width: 170, sort: 'asc' },
  { field: 'contract_or_schedule', headerName: t('Type'), width: 150 },
  { field: 'soldto_name', headerName: t('SoldTo Name'), width: 200 },
  { field: 'soldto_number', headerName: t('SoldTo Account'), width: 150 },
  { field: 'pdf_doc_name', headerName: t('Contract Document'), width: 400, renderCell: renderLink, valueFormatter: (value) => value.pdf_doc_name },
  { field: 'sales_office', headerName: t('Sales Office'), width: 115 },
  { field: 'contract_year', headerName: t('Year'), width: 100 },
  { field: 'contract_user_type', headerName: t(t('User Type')), width: 120 },
  { field: 'acceptance_status', headerName: t('Accept Status'), width: 150 },
  {
    field: 'lock_status',
    headerName: t('Lock Status'),
    width: 150,
    renderCell: (params) => (
      <Checkbox
        checked={params.value}
        onChange={() => handleLockChange(params.row)}
        color="primary"
      />
    ),
  },
  {
    field: 'schedule_amendments',
    headerName: t('Schedule Amendments'),
    width: 200,
    renderCell: (params) => (
      params.row.acceptance_status === 'Accepted' && params.row.contract_or_schedule === 'Contract' ? (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Edit />}
          onClick={() => handleAmendClick(params.row.contract_number)}
        >
          {t("Amend Schedule")}
        </Button>
      ) : (
        <span>{t("Not Available")}</span>
      )
    ),
  },
  {
    field: 'action',
    headerName: t('Action'),
    width: 150,
    renderCell: (params) => {
      const isPending = params.row.acceptance_status === 'Pending User Review';
      return (
        <div className="actions">
          <button
            className="delete-btn"
            onClick={() => handleDeleteClick(params.row)}
            disabled={!isPending}
            style={{
              cursor: isPending ? 'pointer' : 'not-allowed',
              color: 'inherit',
              border: 'none',
              background: 'none',
              padding: 0,
            }}
          >
            <Delete
              fontSize="small"
              style={{ color: isPending ? 'red' : 'gray' }}
            />
          </button>
        </div>
      );
    },
  },
];

function SearchContracts() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  const deleteMutation = useMutation({
    mutationFn: async (contract) => deleteContract(contract),
    onSuccess: () => {
      console.log("Invalidation ...");
      queryClient.invalidateQueries({ queryKey: [TITLE, SEARCH_RESULT_KEY] });
    },
    onError: () => {
      console.error('Failed to delete contract');
    }
  });

  const lockMutation = useMutation({
    mutationFn: async (contract) => updateLockStatus(contract),
    onMutate: () => {
      setLoading(true);
    },
    onSuccess: () => {
      console.log("Lock status updated ...");
      queryClient.invalidateQueries({ queryKey: [TITLE, SEARCH_RESULT_KEY] });
      setSnackbarMessage('Lock status updated successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      setLoading(false);
    },
    onError: () => {
      console.error('Failed to update lock status');
      setSnackbarMessage('Failed to update lock status');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      setLoading(false);
    }
  });

  const handleAmendClick = (contractNumber) => {
    console.log("Amending Contract:::::", contractNumber);
    navigate(`/schedule-amendments/${contractNumber}`);
  };

  const handleDeleteClick = async (contract) => {
    console.log("Deleting the contract:::::", contract);
    try {
      deleteMutation.mutate(contract);
    } catch (error) {
      console.error('Failed to delete contract:', error);
    }
  };

  const handleLockChange = (contract) => {
    const updatedContract = { ...contract, lock_status: !contract.lock_status };
    lockMutation.mutate(updatedContract);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <SearchForm
        title={t(TITLE)}
        requiredInputs={1}
        columns={columns(handleAmendClick, handleDeleteClick, handleLockChange, t)}
        criteriaFn={getContractCriteria}
        searchFn={searchContracts}
      >
        <SearchInput label={t("Contract #")} dataLabel="contractNumber" />
        <SearchDropdown label={t("Contract Template")} dataLabel="templateList" />
        <SearchInput label={t("Sold To Account")} dataLabel="soldToAccount" />
        <SearchDropdown label={t("Contract Status")} dataLabel="statusType" />
        <SearchInput label={t("Sales Office")} dataLabel="salesOffice" />
        <SearchDropdown label={t("Contract User Type")} dataLabel="userTypes" />
        <SearchDropdown label={t("Select Year")} dataLabel="yearList" />
      </SearchForm>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {loading ? <CircularProgress size={24} /> : snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SearchContracts;