import { urls } from "../config";
import { AxiosInstancePrivate, AxiosInstancePublic } from "../utils/AxiosInstance";
import { CONTRACT_TEMPLATE_TYPE, SCHEDULE_TEMPLATE_TYPE, TEMPORARY_TEMPLATE_FILE_NAME } from "../utils/constants";
import axios from "axios";
const privateAxios = AxiosInstancePrivate();
export async function getContractCriteria() {
    const res = await privateAxios.get(urls.contractCriteria);
    return res.data;
}
export async function searchContracts(pagination, sortModel, searchData) {
    const res = await privateAxios.post(urls.searchContracts, { pagination, sortModel, ...searchData });
    return res.data;
}

export const fetchTemplates = async (templateType, t) => {
    try {
        const res = await privateAxios.get(urls.manageTemplates, {
            params: { type: templateType },
        });
        console.log("fetchTemplates response:::::::", res);
        return res.data;
    } catch (err) {
        throw new Error(t('Failed to load contract templates. Please try again later.'));
    }
};

export const uploadTemplate = async (formData, templateType, t) => {
    // console.log("Uploading  template:::::", formData);
    // const formDat = new FormData();
    // formDat.append('contract_template_file', formData.file[0]);
    // formDat.append('contract_type', formData.contractType);
    // formDat.append('boiler_pdf_name', formData.title);
    // formDat.append('type', CONTRACT_TEMPLATE_TYPE);

    let payload = templateType === CONTRACT_TEMPLATE_TYPE ?
        {
            contract_type: formData.contractType,
            boiler_pdf_name: formData.title,
            contract_template_file: formData.file[0],
            type: CONTRACT_TEMPLATE_TYPE,
            total_pages: 5
        } : {
            type: SCHEDULE_TEMPLATE_TYPE,
            pdf_schedule_name: formData.title,
            pro_rate_amount: formData.prorateAmount,
            schedule_number: formData.scheduleNumber,
            schedule_user_type: formData.usertype,
        }


    try {
        const templateDetails = { fileName: `${TEMPORARY_TEMPLATE_FILE_NAME}.pdf`, templateType: templateType };
        const signedUrl = await getSignedUrlForTemplate(templateDetails);
        const uplodResponse = await uploadTemplateUsingSignedUrl(signedUrl, formData.file[0]);
        console.log("Uploading template payload::::;", payload);
        const response = await privateAxios.post(urls.manageTemplates, payload, {
            params: {
                type: templateType
            }
        });
        console.log("uplodResponse:::::::::::", uplodResponse);

        console.log("upload response::::::::", response);
        if (!response.status === 200) throw new Error(t('Failed to upload the template'));
        return response?.data?.message;
    } catch (err) {
        console.log("Failed to upload the contract template", err);
        throw new Error(t('Failed to upload the contract template. Please try again later.'));
    }
};

export const updateTemplate = async (id, formData, templateType, t) => {
    console.log("inside updateTemplate:::::", id, formData, templateType);
    let payload = templateType === CONTRACT_TEMPLATE_TYPE ?
        {
            boiler_plate_id: id,
            type: CONTRACT_TEMPLATE_TYPE,
        } : {
            type: SCHEDULE_TEMPLATE_TYPE,
            schedule_number: id,
        }
    try {
        const templateDetails = { fileName: `${id}_${formData.title}.pdf`, templateType: templateType };
        const signedUrl = await getSignedUrlForTemplate(templateDetails);
        const uplodResponse = await uploadTemplateUsingSignedUrl(signedUrl, formData.file[0]);
        const response = await privateAxios.put(urls.manageTemplates, payload, {
            params: {
                type: templateType
            }
        });
        console.log("update response::::::::", response);
        if (!response.status === 200) throw new Error(t('Failed to update the template'));
        return response?.data?.message;
    } catch (err) {
        console.log("Failed to update the contract template", err);
        throw new Error(t('Failed to update the contract template. Please try again later.'));
    }
};

export const deleteTemplate = async (id, type, t) => {
    console.log("Inside deleteTemplate:::::", id);
    try {
        const response = await privateAxios.delete(urls.manageTemplates, {
            params: {
                type: type,
                boiler_plate_id: id
            },
        });
        console.log("Delete response::::::::", response);
        if (!response.status === 200) throw new Error(t('Failed to delete the template'));
        return response?.data?.message;
    } catch (err) {
        console.log("Failed to delete the contract template", err);
        throw new Error(t('Failed to delete the contract template. Please try again later.'));
    }
};

export const getSignedUrlForTemplate = async (templateDetails) => {
    try {
        const response = await privateAxios.post(urls.getSignedUrl, templateDetails);
        console.log("Signed url:::::", response);
        return response.data;
    } catch (error) {
        console.log("Error getting signed Url", error);
        throw error;
    }
};

export const uploadTemplateUsingSignedUrl = async (signedUrl, file) => {
    console.log("Uploading file type:::::", file.type);
    try {
        const response = await axios.put(signedUrl, file, {
            headers: {
                'Content-Type': file.type,
            },
        });
        return response.data;
    } catch (error) {
        console.log("Error in uploading file using signed url", error);
        throw error;
    }
}