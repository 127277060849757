import { Link, useLocation, useNavigate } from "react-router-dom";
import Heading from "../heading/Heading";
import "./Catalog.css";
import { useEffect, useMemo, useState } from "react";
import { useQueries, useQuery } from "@tanstack/react-query";
import { MenuItem, Select } from "@mui/material";
import { PageLoader } from "../loader/PLoader";
import { getSubCatalog } from "../../service/productsService";
import { useTranslation } from "react-i18next";

function Catalog({ RenderCards ,catalogType}) {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const [pathArr, breadCrumb, currentURI, extraDecoratorUri] = useMemo(() => {
		let wholePath = pathname;
		let extraDecoratorUri = "";
		if (pathname.includes("/consumer/")) {
			wholePath = pathname.replace("/consumer", "");
			extraDecoratorUri = "consumer/";
		} else if (pathname.includes("/pro/")) {
			wholePath = pathname.replace("/pro", "");
			extraDecoratorUri = "pro/";
		}
		const segments = wholePath.split("/");
		const breadCrumb = [];
		segments.slice(2).forEach((v, index) => {
			if (index === 0) {
				breadCrumb.push(v);
			} else {
				breadCrumb.push(`${breadCrumb[index - 1]}/${v}`);
			}
		});
		return [segments.slice(2), breadCrumb, breadCrumb.pop(), extraDecoratorUri];
	}, [pathname]);

	useEffect(() => {
		if (!pathArr[0] || pathArr[0] === "") {
			navigate("/");
		}
	}, [pathArr, navigate]);
	const { i18n } = useTranslation();
	const { isFetching, data, error } = useQuery({
		queryKey: [currentURI,i18n.resolvedLanguage],
		queryFn: async () => getSubCatalog(currentURI,i18n.resolvedLanguage),
		placeholderData: {
			title: t("Loading..."),
			list: {},
		},
	});

	const results = useQueries({
		queries: breadCrumb.map((v) => {
			return {
				queryKey: [v,i18n.resolvedLanguage],
				queryFn: () => getSubCatalog(v,i18n.resolvedLanguage),
			};
		}),
	});

	if (error) {
		if (error.response?.data?.message.includes("Hierarchy of requested category is not correct")) {
			return <> {t("Not a Valid Catalog Path")}</>;
		}
		return <>{t("Something is not right")}</>;
	}
	const isBreadCrumbEmpty = breadCrumb.length === 0 ? true : false;
	return (
		<div className='container catalog__container'>
			<PageLoader show={isFetching} />
			<Heading title={data?.name || ""} />
			<div className='breadcrumbs'>
				<span>{catalogType?catalogType:"Catalog"} </span>
				{results &&
					results.map((result, index) => {
						if (result.isSuccess) {
							return (
								<span key={breadCrumb[index]}>
									{` > `}
									<Link to={`${extraDecoratorUri}${breadCrumb[index]}`}>{result?.data?.name}</Link>
								</span>
							);
						}
					})}
			</div>
			<div className={!isBreadCrumbEmpty ? "catalog__body" : "no__catagory__selector__catalog__body"}>
				{!isBreadCrumbEmpty && (
					<>
						<div className={breadCrumb.length !== 0 ? "catalog__body--selector" : undefined}>
							{results &&
								results?.map((result, index) => {
									if (result.isSuccess) {
										const currPath = breadCrumb[index];
										const selectedValue = pathArr[index + 1];
										if (result.data?.isProduct) {
											return <div key={breadCrumb[index]}></div>;
										}
										return (
											<CategorySelector
												key={breadCrumb[index]}
												path={`${extraDecoratorUri}${currPath}`}
												data={result.data}
												defaultValue={selectedValue}
											/>
										);
									}
								})}
						</div>
						<div className='verticalDivider' />
					</>
				)}
				<RenderCards data={data} t={t} />
			</div>
		</div>
	);
}

function CategorySelector({ data, defaultValue, path }) {
	const decodedDefaultValue = defaultValue ? decodeURIComponent(defaultValue) : null;
	const [selectedValue, setSelectedValue] = useState(decodedDefaultValue);
	const navitageTo = useNavigate();
	const handleNavigate = (event) => {
		setSelectedValue(event.target.value);
		navitageTo(`${path}/${event.target.value}`);
	};

	if (!data?.list) {
		return <></>;
	}
	return (
		<div className='category__selector'>
			<h3>{data?.title}</h3>
			<Select value={selectedValue} onChange={handleNavigate}>
				{Object.keys(data?.list).map((v) => {
					return (
						<MenuItem key={v} value={data?.list?.[v]?.route}>
							{v}
						</MenuItem>
					);
				})}
			</Select>
		</div>
	);
}

export default Catalog;
