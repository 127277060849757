import { Link, useLocation } from "react-router-dom";

import Catalog from "../../component/catalog/Catalog";
import productCardStyle from "../productCatalog/productCatalog.module.css";
import styles from "./logoCatalog.module.css";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { Download, FileOpen } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import AssetPopUp, { downloadAsset } from "../../component/assetPopUp/AssetPopup";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function LogoCatalog() {
	return <Catalog RenderCards={RenderCards} catalogType={"Logos"}/>;
}

function RenderCards({ data }) {
	const { pathname } = useLocation();
	const isAsset = data?.isAsset;
	const queryClient = useQueryClient();
	
	useEffect(() => {
		if (!isAsset || !data?.list) return;
		Object.keys(data?.list).forEach((pimcoreId) => {
		  queryClient.fetchQuery({
			queryKey: ["asset", String(pimcoreId)],
			queryFn: () => data.list[pimcoreId]
		  });
		});
	  }, [data?.list, isAsset, queryClient]);

	return (
		<div className='catalog__body--list' style={isAsset ? { flexDirection: "column" } : {}}>
			 {Object.entries(data?.list).map(([key, entry]) => {
						return <LogoCard key={key} data={{ title: key, ...entry }} path={pathname} isAsset={isAsset} />;
					})}
		</div>
	);
}

export function LogoCard({ data, path, isAsset, hasBackground }) {
	const { t } = useTranslation();
	const { title, route, imageUrl } = data;
    const [assetDisplayId, setAssetDisplayId] = useState(null)
	const [downloading, setDownloading] = useState(false)
	const downloadAnchor=useRef()
	if (isAsset)
		return (
			<div className={styles.logoAssetCard}>
				<a className='anchorForDownload' ref={downloadAnchor}></a>
				<div className={styles["category__card--img"]}>
				<img src={data.largeAssetPath} loading='eager' style={!hasBackground ? { backgroundImage: "none" } : {}} />
				</div>
				<div className={styles["category__card--body"]}>
					<h3 title={data.fileName}>{data.fileName}</h3>
					<p>{t("Asset Type")} : {data?.assetType}</p>
					<p>{t("Extension")} : {data?.extension}</p>
				</div>
				<div className={`btn-group column`}>
					<button
						title='Download'
						className='btn btn-primary'
						onClick={() => {
							downloadAsset(data,downloadAnchor,setDownloading);
						}}>
						<div className='btn-icon'>
							<Download />
                            {t("Download")}
						</div>
						{downloading && <LinearProgress />}
					</button>
					<button
						title='View Details'
						className='btn btn-secondary'
						onClick={() => {
							setAssetDisplayId(data.id);
						}}>
						<div className='btn-icon'>
							<FileOpen />
                            {t("View Details")}
						</div>
					</button>
				</div>
                <AssetPopUp id={assetDisplayId} setAssetDetailsId={setAssetDisplayId} />
			</div>
		);

	return (
		<Link title={title} to={`${path}/${route}`} className={productCardStyle.category__card}>
			<div className={productCardStyle["category__card--img"]}>
				<img src={imageUrl} loading='eager' />
			</div>
			<div className={`horizontal-ruler ${productCardStyle["category__card--ruler"]}`} />
			<div className={productCardStyle["category__card--title"]}>
				<h3>{title}</h3>
			</div>
		</Link>
	);
}
