//TODO remove
export const loggedInSessionKey = "loggedInSessionKey";
export const USER_INFO_KEY = "USER_INFO_KEY";
export const REF_USER_INFO_KEY = "REF_USER_INFO_KEY";
export const COGNITO_ROLES = "COGNITO_ROLES";
export const REF_ROLES = "REF_ROLES";
export const ACCOUNT_ID = "ACCOUNT_ID";
export const CONTACT_ID = "CONTACT_ID";
export const REF_ACCOUNT_ID = "REF_ACCOUNT_ID";
export const REF_CONTACT_ID = "REF_CONTACT_ID";
export const IMPERSONATED = "IMPERSONATED";
export const IMPERSONATED_JWT = "IMPERSONATED_JWT";
export const statusSessionKey = "statusState";
export const SCHEDULE_TEMPLATE_TYPE = "SCHEDULE_TEMPLATE";
export const CONTRACT_TEMPLATE_TYPE = "CONTRACT_TEMPLATE";
export const HEADER_AND_BIZLOCATION_TYPE = "HEADER_AND_BIZLOCATION";
export const REJECT = "Reject";
export const ACCEPT = "Accept";
export const REDIRECT_PATH = "redirectPath"
export const DS_USER_ROLE = "DS_USER_ROLE";
export const CUSTOMER_GROUP = "CUSTOMER_GROUP";
export const USER_TYPE = "USER_TYPE";
export const TEMPORARY_TEMPLATE_FILE_NAME = "temporaryFileName";
export const CONTRACT_HEADER_FILE_NAME = "ContractHeader";
export const BIZ_LOCATION_FILE_NAME = "BusinessLocations";
export const ACCESS_TOKEN = "accessToken";
export const ID_TOKEN = "idToken";
export const USER_STATE = "userState";
export const BULLETIN_TOKEN = "BULLETIN_TOKEN";
export const VIEW_BULLETIN_PATH = "/salesNews/viewBulletin";
export const roleMappings = {
    "0014": "Manager",
    "0015": "Accounting",
    "0016": "Admin",
    "0022": "Sales News",
    "0023": "Dealer Source Only",
    "0025": "ExpressNet View Only",
    "0026": "Principal/Owner",
    "0027": "Purchasing/Buyer",
};
export const S3_PATH = {
    "schedule_template": "contract/templates/",
    "schedule_template": "schedule/templates/"
}
export const SOCIAL_MEDIA_LINKS = {
    "PROFESSIONAL": {
        "Facebook": "https://www.facebook.com/SonyProUSA",
        "Twitter": "https://twitter.com/SonyProUSA",
        "Instagram": "https://www.instagram.com/sonyprousa/",
        "YouTube": "https://www.youtube.com/user/SonyProfessionalUSA",
    },
    "CONSUMER": {
        "Facebook": "https://www.facebook.com/SonyElectronics",
        "Twitter": "http://www.twitter.com/sonyelectronics",
        "Instagram": "http://www.instagram.com/SonyElectronics/",
        "YouTube": "https://youtube.com/Sony",
    }
}

export const USERTYPES={
    "INTERNAL":"INTERNAL",
    "EXTERNAL":"B2B"
}
export const ALLOWED_INDIRECT_ROLES=['DS_INDIRECT_Consumer','DS_Indirect_Pro_Assets']