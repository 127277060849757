import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { Button, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';
import './GenerateContracts.css';
import GeneratedContractsModal from './GeneratedContractsModal';
import { generateContract, listContractTemplateTypes } from '../../../service/contract';
import { PageLoader } from "../../../component/loader/PLoader";
import { useTranslation } from "react-i18next";
const GenerateContracts = () => {
  const { t } = useTranslation();
  const { handleSubmit, control, formState: { errors }, reset } = useForm({
    defaultValues: {
      templateType: ""
    }
  });
  const [templateTypes, setTemplateTypes] = useState([]);
  const [generatedContract, setGeneratedContract] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [headerFile, setHeaderFile] = useState(null);
  const [locationFile, setLocationFile] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const fetchTemplateTypes = async () => {
      try {
        const templateTypes = await listContractTemplateTypes();
        setTemplateTypes(templateTypes);
      } catch (error) {
        console.error("Failed to load template types", error);
      }
    };
    fetchTemplateTypes();
  }, []);

  const onDropHeader = useCallback((acceptedFiles) => {
    setHeaderFile(acceptedFiles[0]);
  }, []);

  const onDropLocation = useCallback((acceptedFiles) => {
    setLocationFile(acceptedFiles[0]);
  }, []);

  const { getRootProps: getHeaderRootProps, getInputProps: getHeaderInputProps } = useDropzone({
    onDrop: onDropHeader,
    accept: '.xlsx'
  });

  const { getRootProps: getLocationRootProps, getInputProps: getLocationInputProps } = useDropzone({
    onDrop: onDropLocation,
    accept: '.xlsx'
  });

  const onSubmit = async (data) => {
    setErrorMessage("");
    setLoading(true); 
    try {
      const response = await generateContract(data, headerFile, locationFile);
      setGeneratedContract(response.data);
      setModalOpen(true);
    } catch (error) {
      console.error("Contract generation failed", error);
      setErrorMessage("Contract generation failed. Please try again.");
      reset(); 
      setHeaderFile(null);
      setLocationFile(null);
      setGeneratedContract(null);
    } finally {
      setLoading(false); 
    }
  };

  const handleCloseModal = () => {
    reset(); 
      setHeaderFile(null);
      setLocationFile(null);
      setGeneratedContract(null);
    setModalOpen(false);
  };

  return (
    <>
      {loading && <PageLoader show={loading} />} 
      <GeneratedContractsModal
        open={modalOpen}
        onClose={handleCloseModal}
        contractData={generatedContract || []}
      />
      <div className="generate-contracts-container">
      <PageLoader show={loading} />
        <h4 className="page-heading">{t("Contract Generation")}</h4>
        <hr className='horizontal-ruler' />
        <div className="generate-contracts-page">
          <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>
            {t("Please attach header and business location file and Generate Contract.")}
          </Typography>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}

          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
              <InputLabel id="template-type-label">{t("Boilerplate Template Type")}</InputLabel>
              <Controller
                name="templateType"
                control={control}
                rules={{ required: "Please select a template type" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    label={t("Boilerplate Template Type")}
                    labelId="template-type-label"
                    error={!!errors.templateType}
                  >
                    <MenuItem value="">
                      <em>-- {t("Select Template Type")} --</em>
                    </MenuItem>
                    {templateTypes?.map((type) => (
                      <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.templateType && (
                <Typography color="error" variant="body2">{errors.templateType.message}</Typography>
              )}
            </FormControl>

            <div className="dropzone-container">
              <div {...getHeaderRootProps()} className="dropzone-area">
                <input {...getHeaderInputProps()} />
                <CloudUploadIcon />
                <Typography variant="body2" color="textSecondary">
                  {headerFile ? headerFile.name : t("Drag and drop Header File, or click to select")}
                </Typography>
              </div>

              <div {...getLocationRootProps()} className="dropzone-area">
                <input {...getLocationInputProps()} />
                <CloudUploadIcon />
                <Typography variant="body2" color="textSecondary">
                  {locationFile ? locationFile.name : t("Drag and drop Business Location File, or click to select")}
                </Typography>
              </div>
            </div>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3 }}
              disabled={loading} 
            >
              {loading ? "Generating Contract..." : t("Generate Contract")} 
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default GenerateContracts;