import { useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import React, { createContext, memo, useContext, useEffect, useState } from "react";
import { PageLoader } from "../loader/PLoader";
import "./search.css";
import SearchResult from "./SearchResult";
import { useQuery } from "@tanstack/react-query";
import ms from "ms";
import Heading from "../heading/Heading";
import Notifications, { NotificationSeverity } from "../errors/Notifications";
import { useTranslation } from "react-i18next";

const SearchFormContext = createContext();

export function useSearchFormContext() {
	const cntx = useContext(SearchFormContext);
	if (!cntx) {
		console.error("Must be a Child of Main Form");
	}
	return cntx;
}
function SearchForm({ children, title, requiredInputs = 0, columns, criteriaFn,searchFn,downloadFns }) {
	const { t } = useTranslation();
	const { isFetching: criteriaFetching, data: criteriaData } = useQuery({
		queryKey: [title , "criteria"],
		queryFn:criteriaFn,
		placeholderData: [],
		//TODO add a  stale time
		gcTime: ms("6h"),
		staleTime: ms("1d"),
	});
const [searchData, setSearchData] = useState({})
	return (
		<div className='container search__container'>
			<PageLoader show={criteriaFetching} />
			<div className='search__body'>
				<MainForm criteriaData={criteriaData} title={title} requiredInputs={requiredInputs} setSearchData={setSearchData} >
					{children}
				</MainForm>
				<SearchResult title={title} columns={columns} searchData={searchData} searchFn={searchFn} downloadFns={downloadFns}/>
			</div>
		</div>
	);
}

const MainForm = memo(function MainForm({ children, criteriaData, requiredInputs, title ,setSearchData}) {
	const {
		register,
		control,
		handleSubmit,
		reset,
		formState: { errors },
		setError,
		clearErrors,
	} = useForm();
	const { t } = useTranslation();
	const onSubmit = (data) => {
		if (Object.keys(data).filter((v) => Boolean(data[v])).length < requiredInputs) {
			Object.keys(data).forEach((v) => {
				if (!Boolean(data[v])) setError(v, { message: t("Should be filled") });
			});

			return;
		} else {
			clearErrors();
		}
		setSearchData(data)
	};
	return (
		<>
		{/* <Notifications type={NotificationSeverity.SUCCESS}/> */}
			<Heading title={title}/>
			<SearchFormContext.Provider value={{ register, control, errors, criteriaData }}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='search__body--criteria'>{children}</div>
					<div className='btn-group'>
						<button
							className='btn btn-secondary'
							type='reset'
							onClick={() => {
								reset();
							}}>
							<div className='btn-icon'>
								<ClearIcon />
								{t("Clear")}
							</div>
						</button>
						<button className='btn btn-primary' type='submit'>
							<div className='btn-icon'>
								<SearchIcon />
								{t("Search")}
							</div>
						</button>
					</div>
				</form>
			</SearchFormContext.Provider>
			{Object.keys(errors).length > 0 ? <div className='inputError'>{t("Please select")} {requiredInputs} {t("input(s)")}!</div> : <></>}
		</>
	);
});
export default SearchForm;
