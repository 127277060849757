import { PDFAssetItem } from "../assets/ProductAssets";
import { useProductDetails } from "../productContext/ProductProvider";
import productStyles from "../productDetails.module.css";
import styles from "./compliance.module.css";
import { useTranslation } from "react-i18next";

function Compliance(){
    const { t } = useTranslation();
    const {
		derivedAssetType,
		assetData,
	} = useProductDetails();

    const complianceAssets=derivedAssetType["Compliance Assets"]

    return (
        <section className={`${styles.compliance} ${productStyles.section}`}>
            <div className={`${productStyles.title}`}>{t("Compliance")}</div>
            <div className={styles.compliance__container}>
                {complianceAssets&&complianceAssets.map((itemId)=>{
                    // const asset=assetData[itemId];
                    return <PDFAssetItem key={itemId} item={{itemId}} t={t}/>})}
            </div>
        </section>
    )
}

Compliance.shouldRender = ({derivedAssetType}) => {
	return derivedAssetType && derivedAssetType["Compliance Assets"] && derivedAssetType["Compliance Assets"].length > 0
};
export default Compliance