import DOMPurify from "dompurify";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Children, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { getProduct, getProductAssets } from "../../service/productsService";
import { useTranslation } from "react-i18next";
import { convertLngToUnderScore } from "../../utils/Utils";
import styles from "./ProductInfo.module.css";
import ProductProvider, { useProductDetails } from "./productContext/ProductProvider";
import AssetPopUp from "../assetPopUp/AssetPopup";
import { useDocumentTitle } from "../../hooks/useDocumentTitle";
import { Page404 } from "../../App";
import ErrorHandler from "../errors/ErrorHandler";
import { ShimmerSkeletonProduct } from "../skeleton/ShimmerSkeleton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const HARD_IGNORE_ASSETS = ["primaryImage", "eCommProductImages"];
const HERO_BANNER_ID = "productBannerHeader";
const DYNAMIC_SCROLL_MARGIN = "385px";

export default function ProductInfo({ children }) {
	const { pathname } = useLocation();
	const { i18n, t } = useTranslation();
	const lng = convertLngToUnderScore(i18n.resolvedLanguage);
	const [productURI, error] = useMemo(() => {
		const pathParts = pathname.split("/");
		return [pathParts[2], pathParts.length > 3];
	}, [pathname]);

	const [assetDisplayId, setAssetDisplayId] = useState(null);
	const [isAssetUpdated, setAssetUpdated] = useState(false);
	const queryClient = useQueryClient();
	const {
		data: productData,
		error: productError,
		isFetching: isFetchingProduct,
	} = useQuery({
		queryKey: ["productInfo", productURI],
		queryFn: () => getProduct(productURI),
	});

	const {
		data: assetData,
		error: assetError,
		isFetching: isFetchingAssets,
	} = useQuery({
		queryKey: ["assets", productURI],
		queryFn: () => getProductAssets(productURI),
	});
	useDocumentTitle(productData?.cfmModelName || t("Product Details"));
	useEffect(() => {
		if (assetData) {
			Object.entries(assetData).forEach(([pimcoreId, assetDetails]) => {
				queryClient.setQueryData(["asset", String(pimcoreId)], assetDetails);
			});
			setAssetUpdated(true);
		}
	}, [assetData]);

	useEffect(() => {
		const handleScroll = async () => {
			// First scroll to top
			await new Promise((resolve) => {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
				setTimeout(resolve, 100);
			});
			// Then handle hash scroll if needed
			if (productData && assetData) {
				const scrollHashId = document.location.hash;
				if (scrollHashId) {
					const divId = scrollHashId?.slice(1);
					const element = document.getElementById(divId);
					if (element) {
						const previousScrollMargin = element.style.scrollMarginTop;
						element.style.scrollMarginTop = DYNAMIC_SCROLL_MARGIN;
						element.scrollIntoView({ behavior: "smooth", block: "start" });
						element.style.scrollMarginTop = previousScrollMargin;
					}
				}
			}
		};
		handleScroll();
	}, [productData, assetData]);

	const derivedAssetType = useMemo(() => {
		if (!assetData || typeof assetData !== "object" || !productData) return [];
		const assets = productData?.assets;
		const allAssets = Object.keys(assets).reduce((acc, key) => {
			const items = assets[key];
			if (!Array.isArray(items)) return acc;
			acc.push(...items);
			return acc;
		}, []);

		const activeOnly = allAssets.filter((value) => {
			return Object.keys(assetData).includes(`${value}`);
		});
		const derivedCategories = activeOnly.reduce((acc, itemId) => {
			const asset = assetData[itemId];
			if (!asset) return acc;
			const category = asset.derivedAssetType;
			if (HARD_IGNORE_ASSETS.includes(category)) return acc;
			if (!acc[category]) acc[category] = [];
			acc[category].push(itemId);
			return acc;
		}, {});

		return derivedCategories;
	}, [assetData, productData]);

	if (error) return <Page404 />;
	else if (productError || assetError) return <ErrorHandler title={"Something Went Wrong"} desc={"Something went wrong"} />;
	else if (isFetchingProduct || isFetchingAssets || !isAssetUpdated) return <ShimmerSkeletonProduct />;
	else
		return (
			<div className={`container ${styles.product__container}`}>
				<ProductProvider
					productDetails={productData}
					assetData={assetData}
					lng={lng}
					setAssetPopUpId={setAssetDisplayId}
					derivedAssetType={derivedAssetType}>
					<ProductContent children={children} />
					<AssetPopUp id={assetDisplayId} setAssetDetailsId={setAssetDisplayId} />
				</ProductProvider>
			</div>
		);
}

function GoToTop() {
	const targetElement = document?.getElementById("ds-main-header");
	targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
}

function ProductContent({ children }) {
	const [showButton, setShowButton] = useState(false);
	const contextData = useProductDetails();

	const handleVisibleButton = useCallback(() => {
		const position = window.scrollY;
		setShowButton(position > 50);
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", handleVisibleButton);

		return () => {
			window.removeEventListener("scroll", handleVisibleButton);
		};
	}, [handleVisibleButton]);

	const handleScrollOnheaderExpand = (event) => {
		event.preventDefault();
		const heroBanner = document?.getElementById(HERO_BANNER_ID);
		if (!heroBanner) return;
		if (heroBanner) {
			const divId = event.target.hash?.slice(1);
			const targetElement = document.getElementById(divId);
			if (!targetElement) return;

			const newUrl = `${window.location.pathname}#${divId}`;
			window.history.pushState({ path: newUrl }, "", newUrl);

			const isBannerSmall = heroBanner.classList.contains(styles.small);

			if (!isBannerSmall) {
				const previousScrollMargin = targetElement.style.scrollMarginTop;
				try {
					targetElement.style.scrollMarginTop = DYNAMIC_SCROLL_MARGIN;
					targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
				} finally {
					targetElement.style.scrollMarginTop = previousScrollMargin;
				}
			} else {
				targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
			}
		}
	};

	const visibilityStates = useMemo(() => {
		return Children.map(children, (child) => ({
			child,
			shouldShow: child.type.shouldRender(contextData),
			headerLink: child.props?.headerLink,
			id: child.props?.id,
		}));
	}, [children, contextData]);

	return (
		<>
			<a id='top'></a>
			<div className={styles.stickySection}>
				<HeroBanner />
				<ul className={styles.sectionNavBar}>
					{/* <li>
					<a href='#top'>Product</a>
				</li> */}
					{visibilityStates.map(
						({ child, shouldShow, id, headerLink }) =>
							headerLink &&
							shouldShow && (
								<li key={id}>
									<a href={`#${id}`} onClick={handleScrollOnheaderExpand}>
										{child.props.displayText}
									</a>
								</li>
							)
					)}
				</ul>
			</div>
			<Headline />
			{visibilityStates.map(
				({ child, shouldShow, id }) =>
					shouldShow && (
						<div key={id} id={id} className={styles.innerLinkTag}>
							{child}
						</div>
					)
			)}
			<button className={`${styles.backToTopButton} ${showButton ? styles.show : ""}`} onClick={GoToTop} title="Go To Top">
				<KeyboardArrowUpIcon fontSize='large' />
			</button>
		</>
	);
}

function HeroBanner() {
	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY;
			setIsScrolled(scrollPosition > 60);
		};
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);
	const { productDetails, lng } = useProductDetails();
	const getDescription = () => {
		const description =
			productDetails.seoDescription?.[lng] || productDetails.seoDescription?.en_US
				? productDetails.seoDescription
				: productDetails.overrideShortDescription;
		return description?.[lng] ?? description?.en_US ?? "";
	};
	return (
		<div id={HERO_BANNER_ID} className={isScrolled ? `${styles.heroBanner} ${styles.small}` : styles.heroBanner}>
			<div className={styles.hero_info}>
				<div className={styles.hero_info_block}>
					<h3>{productDetails.globalModelName ?? productDetails.cfmModelName}</h3>
					{!isScrolled && (
						<div
							dangerouslySetInnerHTML={{
								__html: DOMPurify.sanitize(getDescription()),
							}}></div>
					)}
				</div>
			</div>
			{!isScrolled && (
				<div className='image-wrapper'>
					<img src={productDetails?.imageUrl} alt={productDetails?.cfmModelName} className='contained-image' />
				</div>
			)}
		</div>
	);
}
function Headline() {
	const { productDetails, lng } = useProductDetails();
	const headline = productDetails.headline?.[lng] || productDetails.headline?.en_US;

	if (!headline) {
		return null;
	}

	const headlinePurified = {
		__html: DOMPurify.sanitize(headline),
	};
	return <h2 className={styles.headline} dangerouslySetInnerHTML={headlinePurified}></h2>;
}
