import styles from "./productGallery.module.css";
import productStyles from "../productDetails.module.css";
import { useProductDetails } from "../productContext/ProductProvider";
import EmblaCarousel from "../../embla-carousel/EmblaCarousel";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function ProductGallery() {
	const { t } = useTranslation();
	const { assetData, setAssetPopUpId } = useProductDetails();

	const productImagesId = useMemo(() => {
		return extractProductGallaryIds(assetData).productImagesId;
	}, [assetData]);

	const carouselList = productImagesId.map((v) => {
		const asset = assetData[v];
		return { id: asset.id, image: asset?.largeAssetPath, alt: asset?.fileName };
	});
	const OPTIONS = { dragFree: true, loop: true };

	const handleOnImgClick = (event) => {
		setAssetPopUpId(event.target.id);
	};

	return (
		<section className={`${productStyles.section} ${styles.productGallery}`}>
			<div className={`${productStyles.title} ${styles.galleryTitle}`}>{t("Product Gallery")}</div>
			<div className={styles.productGallery__carousel}>
				<EmblaCarousel options={OPTIONS} slides={carouselList} onClick={handleOnImgClick} />
			</div>
		</section>
	);
}
ProductGallery.shouldRender = ({ assetData }) => {
	return extractProductGallaryIds(assetData).isVisible;
};

function extractProductGallaryIds(assetData) {
	if (!assetData) return { productImagesId: [], isVisible: false };

	const productImagesId = Object.keys(assetData).filter((key) => {
		const asset = assetData[key];
		return asset?.assetMetadata?.assetType === "Product Shot";
	});

	return {
		productImagesId,
		isVisible: productImagesId.length > 0,
	};
}
export default ProductGallery;
