import PolicyIcon from "@mui/icons-material/Policy";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CompanyContactsPage from "../pages/companyContacts/CompanyContactsPage";
import ContractManagementPage from "../pages/contractManagement/ContractManagementPage";
import SearchContracts from "../pages/contracts/search/SearchContracts";
import GenerateContracts from "../pages/contracts/generate/GenerateContracts";
import CampaignIcon from "@mui/icons-material/Campaign";
import ManageContractTemplates from "../pages/contracts/manageTemplates/contractTemplates/ManageContractTemplates";
import ManageScheduleTemplates from "../pages/contracts/manageTemplates/scheduleTemplates/ManageScheduleTemplates";
import SalesNews from "../pages/salesNews/SalesNews";
import MapAndSurePage from "../pages/mapAndSure/MapAndSurePage";
import CompliancePage from "../pages/compliances/CompliancePage";
export const staticMenuItems = [
	{
		route: "sales-news",
		title: "Sales News",
		documentTitle: "Sales News",
		icon: <CampaignIcon />,
		subMenus: null,
		permissions: {
			roles: ["0022", "0026", "0027", "0014", "0016"],
			customerGroups: ["Z1", "Z4", "ZT", "ZB"],
			internal: "*",
		},
		element: <SalesNews />,
	},
	{
		route: "order",
		title: "Order",
		permissions: {
			roles: ["0022", "0026", "0027", "0014", "0016"],
			customerGroups: ["Z1", "Z4", "ZT", "ZB", "Y1", "Y2", "Y4", "Y7"],
			internal: "*",
		},
		icon: <ShoppingCartIcon />,
		subMenus: {
			Products: {
				permissions: {
					roles: ["0022", "0026", "0027", "0014", "0016"],
					customerGroups: ["Z1", "Z4", "ZT", "ZB", "Y1", "Y2", "Y4", "Y7"],
					internal: "*",
				},
				items: {
					"ExpressNet US": {
						permissions: {
							lang: "en-US",
							roles: [],
							customerGroups: ["Z1", "Z4", "ZB", "Y1", "Y2", "Y4", "Y7"],
							internal: "*",
						},
						permaLink: "https://expressnet.sony.com/",
					},
					"ExpressNet CA": {
						permissions: {
							roles: [],
							customerGroups: ["Z1", "Z4", "ZB", "Y1", "Y2", "Y4", "Y7"],
							lang: "en-CA",
							internal: "*",
						},
						permaLink: "https://expressnet.sony.ca/",
					},
					"ExpressNet CF": {
						permissions: {
							roles: [],
							customerGroups: ["Z1", "Z4", "ZB", "Y1", "Y2", "Y4", "Y7"],
							lang: "fr-CA",
							internal: "*",
						},
						permaLink: "https://expressnet.sony.ca/",
					},
					"TechData": {
						permissions: {
							roles: [],
							customerGroups: ["ZT"],
							internal: "*",
						},
						permaLink: "https://ec.synnex.com/SonyExpressNet",
					},
				},
			},
			Parts: {
				permissions: {
					roles: ["0022", "0026", "0027", "0014", "0016"],
					customerGroups: ["Z1", "Z4", "ZT", "ZB", "Y1", "Y2", "Y4", "Y7"],
					internal: "*",
				},
				items: {
					Consumer: {
						permissions: {
							roles: [],
							customerGroups: ["Y1", "Y2", "Y4"],
							internal: "*",
						},
						permaLink: "https://sony.encompass.com/",
					},
					Professional: {
						permissions: {
							roles: [],
							customerGroups: ["Z1", "Z4", "ZT"],
							internal: "*",
						},
						permaLink: "https://sonypro.encompass.com/",
					},
				},
			},
		},
	},
	{
		title: "Rebates & SPIFFs",
		permissions: {
			roles: ["0022", "0026", "0027", "0014", "0023", "0016", "DS_Indirect_Pro_Assets", "DS_INDIRECT_Consumer"],
			customerGroups: ["Z1", "Z4", "ZT", "ZB", "Y1", "Y2", "Y4", "Y7"],
			internal: "*",
			indirect: "*"
		},
		icon: <AttachMoneyIcon />,
		subMenus: {
			Consumer: {
				permissions: {
					roles: ["0022", "0026", "0027", "0014", "0023", "0016", "DS_INDIRECT_Consumer"],
					customerGroups: ["Y1", "Y2", "Y4", "Y7"],
					internal: "*",
					indirect: "*"
				},
				permaLink: "https://rebate.consumer.channel.sony.com",
			},
			Professional: {
				permissions: {
					roles: ["0022", "0026", "0027", "0014", "0023", "0016", "DS_Indirect_Pro_Assets"],
					customerGroups: ["Z1", "Z4", "ZT", "ZB","Y7"],
					internal: "*",
					indirect: "*"
				},
				permaLink: "https://pro.sony/ue_us/rebates",
			},
		},
	},
	{
		route: "contracts",
		title: "Contracts",
		permissions: {
			roles: ["DS_CONTRACT_MANAGER"],
			customerGroups: [],
		},
		icon: <PolicyIcon />,
		subMenus: {
			"Search": {
				route: "search-contracts",
				documentTitle: "Search Contracts",
				permissions: {
					roles: ["DS_CONTRACT_MANAGER"],
					customerGroups: [],
				},
				element: <SearchContracts />,
			},
			"Generate": {
				route: "generate",
				documentTitle: "Generate Contracts",
				permissions: {
					roles: ["DS_CONTRACT_MANAGER"],
					customerGroups: [],
				},
				element: <GenerateContracts />,
			},
			"Manage Contract Templates": {
				route: "manage-contract-templates",
				documentTitle: "Manage Contract Templates",
				permissions: {
					roles: ["DS_CONTRACT_MANAGER"],
					customerGroups: [],
				},
				element: <ManageContractTemplates />,
			},
			"Manage Schedule Templates": {
				route: "manage-schedule-templates",
				documentTitle: "Manage Schedule Templates",
				permissions: {
					roles: ["DS_CONTRACT_MANAGER"],
					customerGroups: [],
				},
				element: <ManageScheduleTemplates />,
			},
		},
	},
	// {
	// 	route: "contracts",
	// 	title: "Contracts",
	// 	icon: <PolicyIcon />,
	// 	subMenus: {
	// 		"Search": { route: "search-contracts", permission: [], element: <SearchContracts /> },
	// 		"Generate": { route: "generate", permission: [], element: <GenerateContracts /> },
	// 		"Manage Templates": { route: "manage-templates", permission: [], element: <ManageTemplates /> },
	// 	},
	// },
	{
		route: "more",
		title: "More",
		permissions: {
			roles: ["0022", "0026", "0027", "0014", "0023", "0016", "DS_Indirect_Pro_Assets", "DS_INDIRECT_Consumer"],
			customerGroups: ["Z1", "Z4", "ZT", "ZB", "Y1", "Y2", "Y4", "Y7"],
			internal: "*",
			indirect: "*"
		},
		icon: <AddCircleOutlineIcon />,
		subMenus: {
			"Compliance": {
				documentTitle: "Compliance",
				route: "compliance",
				element: <CompliancePage />,
				permissions: {
					roles: ["0022", "0026", "0027", "0014", "0023", "0016", "DS_Indirect_Pro_Assets", "DS_INDIRECT_Consumer"],
					customerGroups: ["Z1", "Z4", "ZT", "ZB", "Y1", "Y2", "Y4", "Y7"],
					internal: "*",
					indirect: "*"
				},
			},
			"MAP & SURE": {
				documentTitle: "MAP & SURE",
				route: "map-&-sure",
				documentTitle: "Map & Sure",
				permissions: {
					roles: ["0022", "0026", "0027", "0014", "0023", "0016", "DS_Indirect_Pro_Assets", "DS_INDIRECT_Consumer"],
					customerGroups: ["Z1", "Z4", "ZT", "ZB", "Y1", "Y2", "Y4", "Y7"],
					internal: "*",
					indirect: "*"
				},
				element: <MapAndSurePage />,
			},
			"Useful Links": {
				// permissions: ["Y1", "Y2", "Y4", "Z1", "Z4", "ZT"],
				//TODO remove _U and _C and _CFafter roles implementation
				permissions: {
					roles: ["0022", "0026", "0027", "0014", "0023", "0016", "DS_Indirect_Pro_Assets", "DS_INDIRECT_Consumer"],
					customerGroups: ["Z1", "Z4", "ZT", "ZB", "Y1", "Y2", "Y4", "Y7"],
					internal: "*",
					indirect: "*"
				},
				items: {
					"Pro BRAVIA Knowledge Center": {
						permissions: {
							roles: [],
							customerGroups: [],
							salesOrg: ["US00", "CA00-EN", "CA00-FR"],
							internal: "*",
						},
						permaLink: "https://pro-bravia.sony.net/",
					},
					"Pro Projection Simulator_U": {
						permissions: {
							roles: [],
							customerGroups: [],
							salesOrg: ["US00"],
							lang: "en-US",
						},
						permaLink: "https://pro.sony/ue_US/products/professional-projectors/projection-simulation-software-installers",
					},
					"Pro Crystal LED Configurator_U": {
						permissions: {
							roles: [],
							customerGroups: [],
							salesOrg: ["US00"],
							lang: "en-US",
						},
						permaLink: "https://pro.sony/ue_US/technology/crystal-led-configurator-tool",
					},

					"Pro Projection Simulator_C": {
						permissions: {
							roles: [],
							customerGroups: [],
							salesOrg: ["CA00-EN"],
							lang: "en-CA",
						},
						permaLink: "https://pro.sony/en_CA/products/professional-projectors/projection-simulation-software-installers",
					},
					"Pro Crystal LED Configurator_C": {
						permissions: {
							roles: [],
							customerGroups: [],
							salesOrg: ["CA00-EN"],
							lang: "en-CA",
						},
						permaLink: "https://pro.sony/en_CA/technology/crystal-led-configurator-tool",
					},

					"Pro Projection Simulator_CF": {
						permissions: {
							roles: [],
							customerGroups: [],
							salesOrg: ["CA00-FR"],
							lang: "fr-CA",
						},
						permaLink: "https://pro.sony/qf_CA/products/professional-projectors/projection-simulation-software-installers",
					},
					"Pro Crystal LED Configurator_CF": {
						permissions: {
							roles: [],
							customerGroups: [],
							salesOrg: ["CA00-FR"],
							lang: "fr-CA",
						},
						permaLink: "https://pro.sony/qf_CA/technology/crystal-led-configurator-tool",
					},
				},
			},
		},
	},
	{
		route: "admin",
		title: "Admin",
		icon: <SupervisorAccountIcon />,
		subMenus: {
			"Contract Management": {
				route: "contract-management",
				documentTitle: "Contract Management | Admin",
				permissions: {
					roles: ["0026", "0016"],
					customerGroups: ["Z1", "Z4", "Y1"],
				},
				element: <ContractManagementPage />,
			},
			"Company Contacts": {
				route: "company-contacts",
				documentTitle: "Company Contacts | Admin",
				permissions: {
					roles: ["0026", "0016"],
					customerGroups: ["Z1", "Z4", "ZT", "Y1", "Y2", "Y4", "Y7"],
				},
				element: <CompanyContactsPage />,
			},
		},
		permissions: {
			roles: ["0026", "0016"],
			customerGroups: ["Z1", "Z4", "ZT", "ZB", "Y1", "Y2", "Y4", "Y7"],
		},
	},
];
export const accountSearchPermissions = {
	roles: ["DS_IMPERSONATE"],
	customerGroups: [],
};
export const catalogPermissions = {
	roles: [],
	customerGroups: [],
	internal: "*",
	indirect: "*"
};
export const expiryAssetsPermissions = {
	roles: ["0022", "0026", "0027", "0014", "0023", "0016"],
	customerGroups: ["Z1", "Z4", "ZT", "ZB", "Y1", "Y2", "Y4", "Y7"],
	// internal: "*"
	//TODO only direct ppl allowed
};
