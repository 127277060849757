import { useMemo } from "react";
import DOMPurify from "dompurify";
import { useProductDetails } from "../productContext/ProductProvider";
import styles from "./legalCopy.module.css";
import productStyles from "../productDetails.module.css";
import { useTranslation } from "react-i18next";

function LegalCopy() {
	const { t } = useTranslation();
	const {
		productDetails: { legalCopy },
		lng,
	} = useProductDetails();

	const { legalCopyString } = useMemo(() => getLegalCopyData(legalCopy, lng), [legalCopy, lng]);

	return (
		<section className={`${productStyles.section} ${styles.legalCopy}`}>
			<div className={`${productStyles.title}`}>{t("Legal Copy")}</div>
			<div className={productStyles.sub_section}>
				<p
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(legalCopyString),
					}}></p>
			</div>
		</section>
	);
}

LegalCopy.shouldRender = ({ productDetails: { legalCopy }, lng }) => {
	return getLegalCopyData(legalCopy, lng).isVisible;
};
export function getLegalCopyData(legalCopy, lng ) {
	if (!legalCopy)
		return {
			legalCopyString: "",
			isVisible: false,
		};

	const legalCopyString = legalCopy?.[lng] ?? legalCopy?.en_US ?? "";

	return {
		legalCopyString,
		isVisible: Boolean(legalCopyString),
	};
}
export default LegalCopy;
